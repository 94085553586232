import { ExpandLess, ExpandMore, TableRestaurant } from "@mui/icons-material";
import { useState } from "react";
import { FormatNumber2 } from "./tools";

export const Card = (props) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen((pre) => !pre);
  };
  return (
    <>
      {!props.amount == 0 || !props.amount == null ? (
        <div
          onClick={() => {
            if (props.t === "Running Table") {
              handleClick();
            }
          }}
          className=" group w-full "
        >
          <div
          style={{
            boxShadow:'1px 1px 1px gray'
          }}
            className={`p-2 group   hover:bg-slate-200 ${
              open ? "bg-slate-200" : "bg-[#b1b1b5] "
            }  rounded-lg hover:cursor-pointer hover:z-40 `}
          >
            <div 
             className="flex">
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "95%",
                  flexDirection: "row",
                  textOverflow: "ellipsis",
                  justifyContent: "space-between",
                }}
              >
                {props.t === "Running Table" ? (
                  <TableRestaurant
                    fontSize="15"
                    color={"primary"}
                    sx={{ marginRight: 1 }}
                  />
                ) : null}
                <span
                  className={` ${
                    open ? "text-black" : "text-[#404042] "
                  }    font-medium`}
                >
                  {props.t === "Running Table" ? "Running" : props.t}
                </span>
              </div>
              {props.t === "Running Table" ? (
                open ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore className="ml-2" color="primary" />
                )
              ) : null}
            </div>
            <div
              style={{ fontSize: 15 }}
              className="text-black font-normal  text-md flex pl-1"
            >
              ₹ {FormatNumber2(props.amount)} /-
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
