import "../src/App.css";
import React, { useEffect, useState } from "react";

import 'bootstrap/dist/css/bootstrap.min.css';
import AppRoutes from "./route";



function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("user");
    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    }
    setIsLoggedIn(true);
  };

  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <>
<AppRoutes />
    </>
  );
}

export default App;
