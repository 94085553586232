import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken } from "../../../utility/Redux/security";
import { setSessionExpired } from "../../../utility/Redux/modal";
import { selectPrefix } from "../../../utility/Redux/profile";
import { SelectDate } from "../../../utility/Redux/refresh";
import moment from "moment";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { FormatNumber2 } from "../../../utility/component/tools";
import { Collapse } from "@mui/material";

const BillEditSum = () => {
  const [list, setList] = useState([]);
  const [editList, setEditList] = useState([]);
  const token = useSelector(selectToken);
  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const checkCurrenDate = useSelector(SelectDate);

  const prefix = useSelector(selectPrefix);
  const getData = async () => {
    await axios
      .post(
        "/api/garments/billEditSum",
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        console.log(p);
        if (!p.data.error) {
          setList(p.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        getEditData();
      });
  };
  const getEditData = async () => {
    await axios
      .post(
        "/api/garments/billEditList ",
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        console.log(p);
        if (!p.data.error) {
          setEditList(p.data);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      });
  };
  useEffect(() => {
    getData();
  }, [checkCurrenDate]);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div>
      {editList[0] && (
        <div className="mt-3">
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Bill Edit Sum{" "}
              {open ? (
                <ExpandLess onClick={handleClick} color="secondary" />
              ) : (
                <ExpandMore color="secondary" onClick={handleClick} />
              )}
            </div>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="grid d:grid-cols-5 l:grid-cols-3 t:grid-cols-3 grid-cols-1 sm:grid-cols-1 gap-2 p:gap-2 pt-2">
              {editList[0]
                ? editList.map((d, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          boxShadow: "1px 1px 1px gray",
                        }}
                        className="flex d:flex bg-[#d6e7d7] border rounded-md  hover:cursor-pointer  hover:z-40"
                      >
                        <div className=" p-2 w-full ">
                          <div className="  flex justify-between items-center w-full">
                            <div className="w-full ">
                              <div className="text-[#4a4a4a] font-medium flex justify-between ">
                                {moment(d.DATE).format("DD-MM-YYYY")}{" "}
                                <label className="ml-2 font-bold">
                                  #{d.INVNO}
                                </label>
                              </div>

                              <div className="text-[#4a4a4a] w-full ">
                                <div className="flex font-semibold items-center">
                                  <div className="  text-[14px] w-[70px] ">
                                    Old Amt
                                  </div>
                                  :
                                  <div className="ml-1">
                                    ₹ {FormatNumber2(d.OLD_AMOUNT)}
                                    /-
                                  </div>
                                </div>
                                <div className="flex font-semibold items-center">
                                  <div className="  text-[14px] w-[70px] ">
                                    New Amt
                                  </div>
                                  :
                                  <div className="ml-1">
                                    ₹ {FormatNumber2(d.NEW_AMOUNT)}
                                    /-
                                  </div>
                                </div>
                                <div className="flex font-semibold items-center">
                                  <div className="text-[14px] w-[70px]">
                                    Difference
                                  </div>
                                  :
                                  <div className="ml-1">
                                    ₹{" "}
                                    {FormatNumber2(d.NEW_AMOUNT - d.OLD_AMOUNT)}
                                    /-
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </Collapse>
          <div className="grid d:grid-cols-5 l:grid-cols-3 t:grid-cols-3 grid-cols-1 sm:grid-cols-1 gap-2 p:gap-2 pt-2">
            {list[0]
              ? list.map((d, index) => {
                  {
                    console.log(d);
                  }
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer  hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center w-full">
                          <div className="w-full ">
                            <div className="text-[#4a4a4a] font-medium flex justify-between ">
                              {moment(d.DATE).format("DD-MM-YYYY")}{" "}
                              <label className="ml-2 font-bold">
                                #{d.INVNO}
                              </label>
                            </div>

                            <div className="text-[#4a4a4a] w-full ">
                              <div className="flex font-semibold items-center">
                                <div className="  text-[14px] w-[70px] ">
                                  Old Amt
                                </div>
                                :
                                <div className="ml-1">
                                  ₹ {FormatNumber2(d.OLD_AMOUNT)}
                                  /-
                                </div>
                              </div>
                              <div className="flex font-semibold items-center">
                                <div className="  text-[14px] w-[70px] ">
                                  New Amt
                                </div>
                                :
                                <div className="ml-1">
                                  ₹ {FormatNumber2(d.NEW_AMOUNT)}
                                  /-
                                </div>
                              </div>

                              <div className="flex font-semibold items-center">
                                <div className="  text-[14px] w-[70px] ">
                                  By User
                                </div>
                                :<div className="ml-1">{d.USER}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default BillEditSum;
