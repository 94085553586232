import { useRef } from "react"


export const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  
    return [ htmlElRef, setFocus ] 
  }

  export const useSelect = () => {
    const htmlElRef = useRef(null)
    const setSelect = () => {htmlElRef.current &&  htmlElRef.current.select()}
  
    return [ htmlElRef, setSelect] 
  }

  
export const useFocusSelectAll = () => {
    const htmlElRef = useRef(null)
    const selectAll = () => {
       if(htmlElRef.current){
        htmlElRef.current.focus()
        htmlElRef.current.select() 
        
       } 
        
    }

    return [ htmlElRef, selectAll] 
  }