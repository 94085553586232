import React, { useEffect, useState } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
  setHourlySaleStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";

import { setloading } from "../../../utility/Redux/modal";

import { useNavigate } from "react-router-dom";
import { Card } from "../../../utility/component/card";
import { selectheader } from "../../../utility/Redux/security";
const SectionWiseSale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const table = useSelector(selectPrefix);

  const [SectionWiseData, setSectionWiseData] = useState([]);

  // const ResturantStatus = useSelector(SelectResturantStatus)
  const PaymodeStatus = useSelector(SelectPaymodeStatus);

  const checkDateData = useSelector(SelectDate);

  const headers = useSelector(selectheader);

  const getSectionWiseReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/sectionWiseSaleReport`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        setSectionWiseData(rowList);
        dispatch(setHourlySaleStatus());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getSectionWiseReport();
  }, [checkDateData, PaymodeStatus]);

  return (
    <div>
      {SectionWiseData[0] ? (
        <div className="pb-4 ">
          <div
            style={{ fontSize: 16 }}
            className="flex  pl-1 mb-1 text-brown  text-lg font-bold"
          >
            Section Wise Sale
          </div>
          <div className="grid d:grid-cols-7 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2  pl-2">
            {SectionWiseData[0]
              ? SectionWiseData.map((d, index) => {
                  return (
                    <>
                      {!d.NET_AMT == 0 ? (
                        <div
                          key={index}
                          style={{
                            boxShadow: '1px 1px 1px gray',
                          }}
                          className=" rounded-lg overflow-hidden flex "
                          onClick={() => {
                            navigate("/date_wise_sale", {
                              state: {
                                table_prefix: table,
                                month_name: "",
                                itm_group: "",
                                outlet_name: "",
                                sname: d.SNAME,
                                from_date: checkDateData,
                                to_date: checkDateData,
                                type: d.SNAME,
                                list: SectionWiseData,
                                code: "SNAME",
                                label: "Section",
                              },
                            });
                          }}
                        >
                          {!d.NET_AMT == 0 ? (
                            <Card key={index} t={d.SNAME} amount={d.NET_AMT} />
                          ) : null}
                        </div>
                      ) : null}
                    </>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SectionWiseSale;
