import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { SelectDate } from "../../../../utility/Redux/refresh";
import { selectStatus, setloading } from "../../../../utility/Redux/modal";
import { selectPrefix } from "../../../../utility/Redux/profile";
import { FormatNumber2 } from "../../../../utility/component/tools";
import {
  selectheader,
  selectToken,
} from "../../../../utility/Redux/security";

const DayTaxCollacted = () => {
  const checkDateData = useSelector(SelectDate);
  const [data, setdata] = useState([]);
  const token = useSelector(selectToken);
  const table = useSelector(selectPrefix);
  const [error, setError] = useState(null);
  const loading = useSelector(selectStatus);
  const dispatch = useDispatch();
  const headers = useSelector(selectheader);

  const getDayTaxReport = async () => {
    setError(null);
    dispatch(setloading(true));
    try {
      const response = await axios.post(
        `/api/salesreports/dayTaxCollected`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      );
      setdata(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setloading(false));
    }
  };
  useEffect(() => {
    getDayTaxReport();
  }, [checkDateData]);

  return (
    <div>
      {data[0] ? (
        <>
          <div className="pt-2 pr-3 pl-3 grid d:grid-cols-6 l:grid-cols-5 t:grid-cols-3 grid-cols-3 gap-2 ml:grid-cols-3 sm:grid-cols-3 ms:grid-cols-2  ">
            <div className="bg-white  py-1 px-2  rounded-lg flex  d:flex   border border-green ms:justify-center  sm:justify-start">
              <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black   "
                >
                  Basic Amt. :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2"
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹{FormatNumber2(data[0].BASIC_AMT)} /-
                </div>
              </div>
            </div>

            <div className="  py-1 px-2  bg-white shadow-sm  rounded-lg overflow-hidden flex  d:flex   border border-green ms:justify-center sm:justify-start ">
              <div className="  flex  ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row   ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black flex bg "
                >
                  Discount :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2  "
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹{FormatNumber2(data[0].DISQ_AMT)} /-
                </div>
              </div>
            </div>

            <div className="  py-1 px-2 bg-white shadow-sm  rounded-lg overflow-hidden flex  d:flex   border border-orange ms:justify-center sm:justify-start ">
              <div className="  flex  ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row   ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black flex "
                >
                  Service Chr. :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2  "
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹{FormatNumber2(data[0].SERVICE_CHR)} /-
                </div>
              </div>
            </div>

            <div className="  py-1 px-2 bg-white shadow-sm  rounded-lg overflow-hidden flex  d:flex   border border-green ms:justify-center sm:justify-start ">
              <div className="  flex  ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black flex "
                >
                  CGST :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2 "
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹{FormatNumber2(data[0].CGST)} /-
                </div>
              </div>
            </div>

            <div className="  py-1 px-2 bg-white shadow-sm   rounded-lg overflow-hidden flex  d:flex   border border-green ms:justify-center sm:justify-start  ">
              <div className="  flex  ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black flex   "
                >
                  SGST :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2  "
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹{FormatNumber2(data[0].SGST)} /-
                </div>
              </div>
            </div>

            <div className="  py-1 px-2  bg-white shadow-sm  rounded-lg overflow-hidden flex  d:flex   border border-[#4d7c0f] ms:justify-center sm:justify-start ">
              <div className="  flex  ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row  ">
                <div
                  style={{ fontSize: 13, fontWeight: "500" }}
                  className="text-base font-normal text-black flex  "
                >
                  Net Amt. :
                </div>
                <div
                  className="text-black font-normal text-base flex  pl-2    "
                  style={{ fontSize: 13, whiteSpace: "nowrap" }}
                >
                  ₹ {FormatNumber2(data[0].NET_AMT)} /-
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {data[1] ? (
        <div className="flex w-full justify-center items-center  my-1 sm:hidden ">
          <div
            style={{ height: "1px", fontSize: "12" }}
            className="bg-slate-200 w-2/6 ml:1 text-slate-200"
          />
          <span className="text-slate-300 mx-2 ">Vat</span>
          <div style={{ height: "1px" }} className=" bg-slate-200 w-2/6 mr-1" />
        </div>
      ) : null}

      {data[1] ? (
        <div className=" px-3 pl-3 grid d:grid-cols-6 l:grid-cols-4 t:grid-cols-3 grid-cols-3 gap-2 ml:grid-cols-3 sm:grid-cols-3 sm:mt-2 ms:grid-cols-2    ">
          <div className="bg-white shadow-sm  py-1 px-3   rounded-lg flex  d:flex   border border-green ms:justify-center sm:justify-start ">
            <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row ">
              <div
                style={{ fontSize: 13, fontWeight: "500" }}
                className="text-base font-normal text-black   "
              >
                Basic Amt. :
              </div>
              <div
                className="text-black font-normal text-base flex  pl-2"
                style={{ fontSize: 13, whiteSpace: "nowrap" }}
              >
                ₹{FormatNumber2(data[1].BASIC_AMT)} /-
              </div>
            </div>
          </div>

          <div className=" bg-white shadow-sm py-1 px-2  rounded-lg flex  d:flex   border border-green ms:justify-center sm:justify-start   ">
            <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row  ">
              <div
                style={{ fontSize: 13, fontWeight: "500" }}
                className="text-base font-normal text-black   "
              >
                Discount :
              </div>
              <div
                className="text-black font-normal text-base flex  pl-2"
                style={{ fontSize: 13, whiteSpace: "nowrap" }}
              >
                ₹{FormatNumber2(data[1].DISQ_AMT)} /-
              </div>
            </div>
          </div>

          <div className="  bg-whi py-1 px-2  rounded-lg flex  d:flex   border border-orange ms:justify-center sm:justify-start ">
            <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center sm:flex sm:flex-row  ">
              <div
                style={{ fontSize: 13, fontWeight: "500" }}
                className="text-base font-normal text-black   "
              >
                Service Chr. :
              </div>
              <div
                className="text-black font-normal text-base flex  pl-2"
                style={{ fontSize: 13, whiteSpace: "nowrap" }}
              >
                ₹{FormatNumber2(data[1].SERVICE_CHR)} /-
              </div>
            </div>
          </div>

          <div className=" bg-white shadow-sm  py-1 px-2  rounded-lg flex  d:flex   border border-green ms:justify-center sm:justify-start  ">
            <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center  sm:flex sm:flex-row ">
              <div
                style={{ fontSize: 13, fontWeight: "500" }}
                className="text-base font-normal text-black   "
              >
                Vat :
              </div>
              <div
                className="text-black font-normal text-base flex  pl-2"
                style={{ fontSize: 13, whiteSpace: "nowrap" }}
              >
                ₹{FormatNumber2(data[1].VAT_AMT)} /-
              </div>
            </div>
          </div>

          <div className="bg-white shadow-sm   py-1 px-2  rounded-lg flex  d:flex   border border-[#4d7c0f] ms:justify-center sm:justify-start ">
            <div className="  p:flex ms:flex ms:flex-col ms:justify-center ms:items-center sm:flex sm:flex-row  ">
              <div
                style={{ fontSize: 13, fontWeight: "500" }}
                className="text-base font-normal text-black   "
              >
                Net Amt. :
              </div>
              <div
                className="text-black font-normal text-base flex  pl-2"
                style={{ fontSize: 13, whiteSpace: "nowrap" }}
              >
                ₹{FormatNumber2(data[1].NET_AMT)} /-
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default DayTaxCollacted;
