import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { CustomFooterTotalComponent } from "../Datagrid/fastFood";
import {
  SelectDate,
  setNcSaleStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";
import { selectheader } from "../../../utility/Redux/security";
const FastFoodSaleReport = () => {
  const [fastFoodData, setfastFoodData] = useState([]);
  const checkDateData = useSelector(SelectDate);
  const [total, setTotal] = useState();
  const dispatch = useDispatch();
  const table = useSelector(selectPrefix);
  const headers = useSelector(selectheader);
  const FastFoodColoums = [
    {
      field: "ITM_GROUP",
      width: 150,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div> {"Item Group"} </div>,
    },

    {
      field: "BASIC_AMT",
      headerClassName: "super-app-theme--header",
      align: "right",
      type: "number",
      valueFormatter: (params) => params.value.toLocaleString(),
      headerAlign: "right",
      renderHeader: (params) => <div> {"Amount"} </div>,
      width: 150,
    },
    {
      field: "SERVICE_CHR",
      align: "right",
      headerAlign: "right",
      valueFormatter: (params) => params.value.toLocaleString(),
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div> {"Service Charge"} </div>,
      width: 150,
    },
    {
      field: "CGST_AMT",
      align: "right",
      valueFormatter: (params) => params.value.toLocaleString(),
      headerAlign: "right",

      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div> {"CGST Amount"} </div>,
      width: 150,
    },
    {
      field: "SGST_AMT",
      align: "right",
      valueFormatter: (params) => params.value.toLocaleString(),
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div> {"SGST Amount"} </div>,
      width: 150,
    },
    {
      field: "NET_AMT",
      align: "right",
      valueFormatter: (params) => params.value.toLocaleString(),
      headerAlign: "right",
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => <div> {"Net Amount"} </div>,
      width: 150,
    },
  ];

  const getFastFoodReport = () => {
    axios
      .post(
        `/api/salesreports/fastFoodSale`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        setfastFoodData(rowList);
        dispatch(setNcSaleStatus());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // getFastFoodReport()
  }, []);

  return (
    <div className="bg-slate-200 rounded-lg overflow-hidden h-64 m-1">
      <DataGrid
        hideFooterPagination
        rowsPerPageOptions={[100, 500, 1000, 1500]}
        sx={{ backgroundColor: "#e6e9f0" }}
        pageSize={100}
        components={{
          Footer: CustomFooterTotalComponent,
        }}
        componentsProps={{
          footer: { total },
        }}
        onStateChange={(state) => {
          const total = fastFoodData
            .map((item) => item.NET_AMT)
            .reduce((a, b) => a + b, 0);

          setTotal(total);
        }}
        rowHeight={34}
        editMode={"row"}
        experimentalFeatures={{ newEditingApi: true }}
        rows={fastFoodData}
        columns={FastFoodColoums}
      />
    </div>
  );
};

export default FastFoodSaleReport;
