import axios from "axios";
import React, { useEffect, useState } from "react";
import { generateHmac } from "../utility/function";

const ActivationAccount = () => {
  const searchParams = new URLSearchParams(window.location.search);
  // const param1 = searchParams.get('company_email');
  const userEmail = searchParams.get("user_email");
  const activationCode = searchParams.get("activate_code");
  const [status, setStatus] = useState("pending");

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${generateHmac(userEmail)}`,
  };

  const ActivateUser = () => {
    axios
      .post(
        "api/users/activateUser",
        {
          user_email: userEmail,
          activate_code: activationCode,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        setStatus("activated");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (activationCode) {
      ActivateUser();
    }
  }, []);
  return <div className="h-screen w-screen p-4">Account is {status}</div>;
};

export default ActivationAccount;
