import CryptoJS from 'crypto-js';

function generateHmac(u) {
    const secretKey = process.env.REACT_APP_AUTH_SECRET_KEY
  // Generate HMAC SHA256 hash using the secret key
  const hmac = CryptoJS.HmacSHA256(u, secretKey);
  
  // Convert the HMAC to a string and encode it using Base64
  const hmacString = CryptoJS.enc.Base64.stringify(hmac);

  return hmacString;
}

export {generateHmac}