import React, { useCallback, useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import moment from "moment/moment";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompany,
  selectPrefix,
} from "../../../../utility/Redux/profile";
import { setloading } from "../../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { FormatNumber2 } from "../../../../utility/component/tools";
import { useParams, useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";
import ReactToPrint from "react-to-print";
import { selectheader } from "../../../../utility/Redux/security";
const columns = [
  {
    field: "month",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <span className="text-black">{"Month"} </span>,
  },
];

export const ItemGroupColoum = [
  {
    field: "FUNCTION_DATE",
    headerClassName: "super-app-theme--header",

    valueFormatter: (params) => {
      return moment(params.value).format("DD-MM-YYYY");
    },
    renderHeader: (params) => <div> {"Function Date"} </div>,
    width: 100,
  },
  {
    field: "VENUE",
    headerClassName: "super-app-theme--header",

    renderHeader: (params) => <div> {"Venue"} </div>,
    width: 110,
  },
  {
    field: "FUNCTION_TYPE",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Function Type"} </div>,
    width: 120,
  },
  {
    field: "START_TIME",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"From Time"} </div>,
    width: 80,
  },
  {
    field: "END_TIME",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"To Time"} </div>,
    width: 80,
  },

  {
    field: "CONFIRM_TEN",
    align: "left",
    headerAlign: "left",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Confirm"} </div>,
    width: 80,
  },
  {
    field: "EXPECTED_PAX",
    align: "right",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Exp. Pax"} </div>,
    width: 80,
  },

  {
    field: "GRTD_PAX",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Guaranteed Pax"} </div>,
    width: 110,
    align: "right",
  },
  {
    field: "RENTAL_CATERING",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Rental"} </div>,
    width: 80,
  },
  {
    field: "NAME",

    headerClassName: "super-app-theme--header",
    flex: 1,
    renderHeader: (params) => <div> {"Guest Name"} </div>,
  },
  {
    field: "MOBILE",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Mobile"} </div>,
    width: 110,
  },
  {
    field: "AMOUNT",
    align: "right",
    valueFormatter: (params) => FormatNumber2(params.value.toFixed(2)),
    headerAlign: "right",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Amount"} </div>,
    width: 100,
  },

  {
    field: "BALANCE",
    align: "right",
    valueFormatter: (params) => FormatNumber2(params.value.toFixed(2)),
    headerAlign: "right",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Balance"} </div>,
    width: 100,
  },
];

const FunctionBooking = () => {
  const location = useLocation();
  const monthPrevius = location.state.month;

  const [pageSize, setPageSize] = useState(100);
  const [ItemReport, setItemReport] = useState([]);

  const navigate = useNavigate();

  const [groupName, setgroupName] = useState(monthPrevius);

  const table = useSelector(selectPrefix);
  const dispatch = useDispatch();

  const [month, setMonth] = useState([]);
  const componentRef = useRef();
  const headers = useSelector(selectheader);

  const getItemReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesSubReport/monthlyFunctions`,
        {
          table_prefix: table,
          month_name: monthPrevius,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
        }

        setItemReport(rowList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
        // filter();
      });
  };

  useEffect(() => {
    getItemReport();
  }, [groupName]);

  return (
    <div className="flex-cols p:flex px-2 pt-20  bg-slate-50 h-full  w-full  p:pt-9  pb-2   ">
      {/* second  */}

      <div className="flex flex-col w-full  h-full ">
        <div className="flex items-center  mb-1 ">
          <div className="bg-[#fde3d9] rounded-lg max-w-max  flex justify-between items-center  px-1 py-1 ">
            <span style={{ fontSize: 14 }} className={`text-black  px-2 w-20`}>
              {" "}
              {groupName === "" ? "All        " : monthPrevius}
            </span>
          </div>
        </div>
        <div ref={componentRef} className=" h-64 print grow print:block   ">
          <Box
            sx={{
              backgroundColor: "white",
              "& .super-app-theme--header": {
                backgroundColor: "#E5E7EB",
              },
              height: "100%",
            }}
          >
            <DataGrid
              showCellRightBorder
              rowsPerPageOptions={[25, 50, 75, 100]}
              sx={{ backgroundColor: "white", fontSize: 13 }}
              onPageSizeChange={(size) => setPageSize(size)}
              pageSize={pageSize}
              rowHeight={34}
              editMode={"row"}
              experimentalFeatures={{ newEditingApi: true }}
              rows={ItemReport}
              columns={ItemGroupColoum}
              showColumnRightBorder={true}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default FunctionBooking;
