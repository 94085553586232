import React, { useEffect, useState } from "react";

import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { selectPrefix } from "../../../../utility/Redux/profile";
import { setloading } from "../../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { SelectDate } from "../../../../utility/Redux/refresh";
import { FormatNumber2 } from "../../../../utility/component/tools";
import { Edit, HistoryEdu, TableRestaurant } from "@mui/icons-material";
import { selectheader } from "../../../../utility/Redux/security";

const Running = () => {
  const location = useLocation();
  const checkDateData = useSelector(SelectDate);
  const [tableReport, setTableReport] = useState([]);

  const navigate = useNavigate();
  const table = useSelector(selectPrefix);
  const dispatch = useDispatch();

  const headers = useSelector(selectheader);

  const getRunningTAbleNo = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesSubReport/runningTableNo`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setTableReport(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getRunningTAbleNo();
  }, [checkDateData]);

  return (
    <div className="grid d:grid-cols-10 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2   pt-3 ">
      {tableReport.map((d) => {
        return (
          <div className="border border-orange  py-1 px-1 rounded-lg">
            <div>
              <div className="flex">
                <div style={{ flex: 1 }} className="flex items-center  ">
                  <TableRestaurant fontSize="15" color="secondary" />
                  <span
                    style={{
                      fontSize: 16,
                      marginLeft: 8,
                      fontWeight: 500,
                      marginRight: 2,
                    }}
                    className="flex"
                  >
                    {d.TCODE}
                  </span>
                  <span className="text-brown " style={{ fontSize: 11 }}>
                    ({" " + d.TNAME.substring(0, 1)}
                    {d.TNAME.substring(1, 100).toLowerCase() + " "})
                  </span>
                </div>
              </div>

              <div className="flex mt-0.5">
                <span
                  style={{ fontSize: 12, marginLeft: 8, fontWeight: 500 }}
                  className="flex"
                >
                  ₹ {FormatNumber2(d.NET_AMT)}/-{" "}
                </span>
              </div>

              <div className="flex justify-end text-slate-500 ">
                <span style={{ fontSize: 10 }}>
                  {d.WNAME.substring(0, 1)}
                  <span className="lowercase">{d.WNAME.substring(1, 100)}</span>
                </span>
              </div>
            </div>
          </div>
        );
      })}

      {/* second  */}
    </div>
  );
};

export default Running;
