import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { selectCheckEmailStatus, setCheckEmailAlert } from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/ico.png';

export default function CheckEmailModall() {
  const dispatch = useDispatch();
  const checkEmailStatus = useSelector(selectCheckEmailStatus);

  const handleClose = () => dispatch(setCheckEmailAlert(false));

  return (
    <div>
      <Modal open={checkEmailStatus} onClose={handleClose}>
        <div
          className="   bg-white overflow-hidden rounded-lg   "
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            divShadow: 24,
          }}
        >
          <div className=" flex  flex-col justify-center p-4 ">
            <div className="   flex flex-col  justify-center items-center  ">
              <img
                src={require('../../images/WGLOGO.png')}
                className=" w-24  -mt-0.5 "
                alt=" "
              />
              {/* <img src = {require('../../images/GO92X12.png' )} style={{height:20}}  alt=' ' /> */}
              {/* <span style={{fontSize:8 ,fontWeight:'100' ,letterSpacing:1 }} className='text-slate-50 '>Since 1994.</span> */}
              <div className="text-black mt-2 mb-4  ">
                <span style={{ fontSize: 18, fontWeight: 'bold' }}>
                  Reset Your Password
                </span>
              </div>
            </div>

            <div className="flex text-black  flex-col justify-center bg-white text-center  rounded-lg ">
              <span style={{ fontSize: 15 }}>
                Check email to reset your password, if it does not appear.
                <br />
                Please check your spam folder.
              </span>

              <div className="mt-5">
                <Button
                  style={{
                    backgroundColor: '#383535',
                  }}
                  variant="contained"
                  onClick={() => dispatch(setCheckEmailAlert(false))}
                >
                  {' '}
                  <div style={{ textTransform: 'capitalize' }}>
                    {' '}
                    <span
                      style={{ fontWeight: 600 }}
                      className="text-white tracking-wider"
                    >
                      Return to Sign in
                    </span>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
