import { yupToFormErrors } from "formik";
import * as Yup from "yup";

export const SchemaValidate = Yup.object({
  name: Yup.string()
    .required("Required")
    .min(2, "At least 2 characters")
    .max(20),
  email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required").min(6).max(10),
  confirm: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "password must be same"),
});

export const SchemaLogin = Yup.object({
  Email: Yup.string().email().required("Required"),
  login_password: Yup.string()
    .required("Required")
    .min(6, "At least 6 character")
    .max(10),
});

export const SchemaResetEmail = Yup.object({
  companyEmail: Yup.string()
    .email("Must be a valid email")
    .required("Required"),
  userEmail: Yup.string().email("Must be a valid email").required("Required"),
  // newPassword:Yup.string().required('Required').min(6,'At least 6 character').max(10),
  // confirmPassword: Yup.string().required('Required').oneOf([Yup.ref('newPassword'),null],'password must be same'),
});

export const SchemaChangedPassword = Yup.object({
  newPassword: Yup.string()
    .required("Required")
    .min(6, "At least 6 character")
    .max(10),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("newPassword"), null], "password must be same"),
});

export const SchemaValidateCompany = Yup.object({
  company: Yup.string()
    .required("Required")
    .min(4, "At least 4 characters")
    .max(50),
  email: Yup.string().email("Must be a valid email"),
  group: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  mobile: Yup.string()
    .required("Required")
    .min(10, "Enter Vaild Mobile Number")
    .max(10),
  password: Yup.string().min(6, "Minimum 6 digit"),
  confirmPassword: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "password must be same"),
  userName: Yup.string().required("Required").min(6, "Minimum 6 letters"),
  userEmail: Yup.string().required("Required").email("Must be a valid email"),
  // companyPassword : Yup.string().min(6,'Minimum 6 digit'),
});
