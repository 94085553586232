import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import DatePicker from "react-datepicker";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "./Dashboard/dashboard";
import { useDispatch, useSelector } from "react-redux";
import icon from "../utility/images/icon.svg";

import { CalendarMonth, Close, ExitToApp, Logout } from "@mui/icons-material";
import {
  selectCompany,
  selectCompanyType,
  selectPrefix,
  selectUserName,
} from "../utility/Redux/profile";
import { parseISO } from "date-fns";
import moment from "moment/moment";
import { setDate } from "../utility/Redux/refresh";
import "react-datepicker/dist/react-datepicker.css";
import { selectStatus, setAlertWithTitle, setCustomeTwoButtomAlert } from "../utility/Redux/modal";
import { SelectDate } from "../utility/Redux/refresh";
import { BarLoader } from "react-spinners";
import CustomeTwoButtonAlert from "../utility/component/modals/twoButtonAlert";
import useCookies from "react-cookie/cjs/useCookies";
import SpinnerLoading from "../utility/component/modals/spinnerLoading";
import SessionOutModal from "../utility/component/modals/sessionExpired";
import GarmentDashboard, {
} from "./Dashboard/dashboard3";
import { HandleIndexedDB } from "../utility/indexDb";
import AlertModal2 from "../utility/component/modals/alertWithLottie";
const drawerWidth = 215;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Home = () => {
  const [MobileMenu, setMobileMenu] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const companyname = useSelector(selectCompany);
  const loading = useSelector(selectStatus);
  const [dateFocus, SetDateFocus] = useState(false);
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName);
  const checkDateData = useSelector(SelectDate);
  const [expiredIn, setExpiredIn] = useState('');

  const table = useSelector(selectPrefix);
  const [cookies, setCookie, removeCookie] = useCookies(["myCookie"]);

  const active = cookies.myCookie ? cookies.myCookie.activeOption : null;
  const company_type = useSelector(selectCompanyType);
  // const company_type = "Restaurant Hotel";
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  console.log(company_type);

  const menuDrawer =
    company_type === "Restaurant Hotel"
      ? ["Dashboard", "ItemGroup", "Front Office"]
      : company_type === "Restaurant"
      ? ["Dashboard", "ItemGroup"]
      : ["Dashboard"];


      useEffect(() => {
        if (!expiredIn) {
          const databaseName = 'userDetails';
          const storeName = 'data';
    
          HandleIndexedDB({
            databaseName,
            storeName,
            callback: (data) => {
              if (data[0]) {
                const endDate = moment(data[0].end_date).diff(
                  moment(moment().format('YYYY-MM-DD')),
                  'days'
                );
                if (endDate < 11) {
                  setExpiredIn(endDate);
                } else {
                  if (endDate < 4) {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: `License will expire in ${endDate} days.`,
                      })
                    );
                  }
                }
              }
            },
          });
        }
      }, []);
  return (
    // <Box className="flex h-screen  w-screen overflow-y-hidden  ">
    <div className="flex h-screen  w-screen overflow-y-hidden  ">
      <div className="print:hidden">
        <AppBar
          elevation={2}
          position="fixed"
          open={open}
          style={{
            height: 50,
            justifyItems: "start",
            backgroundColor: "#0d0d0d",
          }}
        >
          <Toolbar
            style={{ height: 50, justifyItems: "start" }}
            className={"t:-mt-[10px] -mt-[6px]  flex items-center"}
          >
            {window.innerWidth <= 600 ? (
              <MenuIcon
                sx={{ marginRight: 1 }}
                onClick={() => setMobileMenu(!MobileMenu)}
              />
            ) : (
              <MenuIcon
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  marginRight: 1,
                  marginLeft: -1,

                  ...(open && { display: "none" }),
                }}
                onClick={handleDrawerOpen}
              />
            )}

            <div className="   flex grow justify-between items-center  w-full   ">
              <div className="flex">
                <div
                  className={`${
                    open ? "hidden" : "flex items-center w-160  "
                  } `}
                >
                  <img
                    src={require("../utility/images/GOROUND2.png")}
                    alt=""
                    style={{ width: "30px", height: "auto" }}
                  />
                  <img
                    src={require("../utility/images/GO92X12.png")}
                    alt=""
                    className=" ml:w-24 h-auto ms:w-16 mm:w-24  "
                  />
                </div>

                <div className="hidden t:flex">
                  <div
                    className={` bg-[#f5874f] mt-1   ${
                      open ? "hidden  " : null
                    } t:ml-5  ml-2 ml:ml-2  `}
                    style={{ width: "0.5px", height: 50 }}
                  />

                  <div
                    className={`  flex justify-between  items-center ml-2  t:flex`}
                  >
                    <div
                      style={{ fontSize: 16 }}
                      className={"tracking-[.1em]  print:hidden  "}
                    >
                      SalesDesk
                    </div>
                  </div>
                </div>
              </div>

              <div
                style={{ fontSize: 16 }}
                className={"tracking-[.1em] flex items-center ml-4"}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",

                    textOverflow: "ellipsis",
                  }}
                  className=" max-w-[120px] d:max-w-[300px] "
                >
                  <span className="pl-2 capitalize  "> {userName}</span>{" "}
                </div>
                <div
                  style={{ width: "2px", height: 20 }}
                  className=" bg-orange mr-3 ml-2  "
                />
                <div className={`flex items-center text-orange  `}>
                  <button
                    onClick={() => {
                      dispatch(setCustomeTwoButtomAlert(true));
                    }}
                  >
                    <ExitToApp />
                  </button>
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <Drawer
        variant="permanent"
        open={open}
        className={`${" p:flex absolute "} `}
      >
        <div className="bg-[#0d0d0d]  print:hidden  ">
          <DrawerHeader className=" justify-start items-start h-full mt-2   ">
            <div className="flex w-full justify-center   ">
              <div className="flex flex-col items-center ">
                <img alt="" src={icon} className="h-auto  w-40 " />
              </div>
            </div>
            <div
              style={{
                zIndex: 100,
                marginRight: "13px",
              }}
              className="  h-full  items-start "
            >
              {" "}
              <Close color="white" onClick={() => setOpen(false)} />
            </div>
          </DrawerHeader>
        </div>

        <div
          className={`${open ? "bg-[#0d0d0d] pt-10" : " -mt-7  bg-[#0d0d0d]"} `}
        >
          <List
            sx={{
              width: "100%",
            }}
          >
            {menuDrawer.map((text, index) => (
              <ListItem
                sx={{
                  width: "100%",
                }}
                onClick={() => {
                  setOpen(false);
                  if (text === "Dashboard") {
                    navigate("/");
                  }
                  if (text === "ItemGroup") {
                    navigate("/item_group", {
                      state: {
                        table_prefix: table,
                        from_date: moment().format("YYYY-MM-DD"),
                        to_date: moment().format("YYYY-MM-DD"),
                        itm_group: "",
                      },
                    });
                  }
                  if (text === "Front Office") {
                    navigate("/sale_desk_rooms");
                  }

                  setSelectedMenu(text);
                  setMobileMenu(false);
                }}
                key={text}
                disablePadding
              >
                <div
                  className={`${
                    !open && selectedMenu === text
                      ? "bg-[#262626]  "
                      : !open && "hover:bg-[#2e2d2d]"
                  } w-full  cursor-pointer`}
                  style={
                    selectedMenu === text && open
                      ? {
                          color: "white",
                          display: "flex",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          paddingTop: "1px",

                          flexDirection: "row",
                          alignItems: "center",
                        }
                      : {
                          color: "#f2f4f7",
                          paddingLeft: "10px",
                          paddingTop: "1px",

                          paddingRight: "10px",

                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }
                  }
                >
                  <div
                    className={`flex ${open && "hover:bg-[#2e2d2d]"}  ${
                      selectedMenu === text && open && "bg-[#262626]"
                    } w-full p-2 pl-2 items-center rounded-[7px]`}
                  >
                    <div className="">
                      {text === "Dashboard" && (
                        <div
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Dashboard"
                          className="w-10  flex justify-start"
                        >
                          <i className="bi  text-[18px]  bi-columns-gap"></i>
                        </div>
                      )}
                      {text === "ItemGroup" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10   flex justify-start`}
                        >
                          IG
                        </div>
                      )}
                      {text === "Front Office" && (
                        <div
                          style={{
                            fontSize: "15px",
                          }}
                          data-tooltip-place="right"
                          data-tooltip-id="tip2"
                          data-tooltip-content="Masters"
                          className={`w-10   flex justify-start`}
                        >
                          FO
                        </div>
                      )}
                    </div>
                    <div>{text}</div>
                  </div>
                </div>
              </ListItem>
            ))}
      
          </List>
        </div>
        <div className="h-full bg-[#0d0d0d] flex flex-col justify-end pb-0 items-center print:hidden ">
          <div style={{ height: "0.5px" }} className=" bg-[#a9a9a9]  w-11/12" />
          <ListItem disablePadding>
            <ListItemButton
              style={{ paddingLeft: 12 }}
              onClick={() => {
                dispatch(
                  setCustomeTwoButtomAlert({
                    status: true,
                  })
                );
                setMobileMenu(false);
              }}
            >
              <ListItemIcon
                onClick={() => {
                  dispatch(
                    setCustomeTwoButtomAlert({
                      status: true,
                    })
                  );
                  setMobileMenu(false);
                }}
              >
                <Logout style={{ fontSize: 24 }} className="text-[#a9a9a9]" />
              </ListItemIcon>
              <ListItemText
                primary="Logout"
                className="text-[#a9a9a9]"
                style={{ marginLeft: -18 }}
                primaryTypographyProps={{
                  variant: "subtitle",
                  style: { fontSize: 16 },
                }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </Drawer>

      <div
        style={{ paddingTop: 48 }}
        className="  w-full pl-12"
        onClick={() => {
          setOpen(false);
        }}
      >
        <div className=" flex-cols grow w-full print:hidden  ">
          <div
            style={{
              width: window.innerWidth - 50,
            }}
            className="t:flex justify-between h-[36px] bg-slate-100  px-2  py-1 border-b border-gray  z-10  fixed   items-center pl-3"
          >
            <div className="flex  items-center  flex-1 justify-between ">
              <div
                className="flex     items-center "
                style={{ fontSize: 16, paddingLeft: 0 }}
              >
                <div className="flex flex-1 items-center ">
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      fontWeight: 500,
                    }}
                  >
                    {companyname}
                  </span>
                  
                </div>

                <div
                  style={{ width: "2px", height: 16 }}
                  className=" bg-brown ml:ml-3 mm:ml-3 ms:ml-1  flex  "
                />
                   {expiredIn && (
                    <div
                      style={{
                        letterSpacing: '2px',
                      }}
                      className="ml-4 font-semibold text-[#cf4848] "
                    >
                      - License will be expired in {expiredIn} days
                    </div>
                  )}
              </div>
              <div className="flex absolute right-0  items-center  ml-2  z-40  ">
                <div
                  className=" "
                  style={
                    window.innerWidth <= 1024
                      ? { display: "none", visibility: "hidden" }
                      : { display: "flex" }
                  }
                >
                  {moment(checkDateData).format(" dddd ")}
                  <div className="pl-1 pr-1">-</div>
                </div>
                <DatePicker
                  readOnly={true}
                  open={ dateFocus
                  }
                  onInputClick={() => SetDateFocus((o) => !o)}
                  onFocus={() => SetDateFocus(false)}
                  onClickOutside={() => SetDateFocus(false)}
                  onSelect={() => SetDateFocus(false)}
                  className=" bg-transparent   text-right t:text-left w-[180px] mr-1"
                  dateFormat=" d MMMM , yyyy "
                  selected={parseISO(
                    moment(checkDateData).format("YYYY-MM-DD")
                  )}
                  onChange={(date) => {
                    dispatch(setDate(moment(date).format("YYYY-MM-DD")));
                    // SetDateFocus(false)
                  }}
                />

                {location.pathname === "/" ||
                location.pathname === "/sale_desk_rooms" ? (
                  <CalendarMonth
                    color="primary"
                    onClick={() => {
                      SetDateFocus(true);
                    }}
                  />
                ) : (
                  <div className="flex items-center">
                    <div
                      style={{ width: 2, height: 16 }}
                      className=" bg-brown mx-3  flex "
                    />
                    <div className="flex w-32 capitalize whitespace-nowrap  ">
                      {" "}
                      {location.pathname
                        .substring(1)
                        .replace("_", " ")
                        .replace("_", " ")}{" "}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{ marginLeft: 0, fontSize: 13 }}
              className=" text-brown  flex mt-1 ms:hidden"
            >
              <div
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                Current Date :
              </div>
              <div className="flex pl-1">
                {moment(new Date()).format(" dddd ")}
                <div className="pl-1">-</div>
                <DatePicker
                  open={false}
                  readOnly
                  className="bg-transparent  w-32 mr-10 "
                  dateFormat="d MMMM , yyyy "
                  selected={new Date()}
                />
              </div>
            </div>
          </div>
          <div className="h-0.5 ">
            <BarLoader
              color={"#F5874F"}
              loading={loading}
              cssOverride={{
                top: window.innerWidth <= 710 ? 36 : 32,
                width: "auto",
              }}
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={1}
            />
          </div>
        </div>
        <div className=" h-full  w-full bg-slate-100 overflow-y-scroll">
          {location.pathname === "/" ? (
            company_type === "Garments" ? (
              <GarmentDashboard />
            ) : (
              <Dashboard />
            )
          ) : (
            <Outlet />
          )}
        </div>
      </div>

      <CustomeTwoButtonAlert />
      <SpinnerLoading />
      <SessionOutModal />
      <AlertModal2 />
    </div>
    // </Box>
  );
};

export default Home;
