import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
const prefixEncrypted = localStorage.getItem("prefix");
const prefixEncrypted1 = localStorage.getItem("prefix1");
const prefixEncrypted2 = localStorage.getItem("prefix2");
// const secretPass2 = "XkhZG4fW2t2W";
const secretPass = "zzjhsbd2iusk";

const company = localStorage.getItem("company");
const userName = localStorage.getItem("userName");
const company_type = localStorage.getItem("company_type");
const getPrefix = () => {
  if (prefixEncrypted) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted, secretPass);
    if (bytes.sigBytes < 0) {
     localStorage.clear()
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};
const getPrefix1 = () => {
  if (prefixEncrypted1) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted1, secretPass);
    if (bytes.sigBytes < 0) {
     localStorage.clear()
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};
const getPrefix2 = () => {
  if (prefixEncrypted2) {
    const bytes = CryptoJS.AES.decrypt(prefixEncrypted2, secretPass);
    if (bytes.sigBytes < 0) {
     localStorage.clear()
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var prefix = data;

      return prefix;
    }
  }

  return null;
};
export const Profile = createSlice({
  name: "profile",
  initialState: {
    userName: userName,
    prefix: getPrefix(),
    prefix2: getPrefix2(),
    prefix1: getPrefix1(),
    company: company,
    company_type: company_type,
  },
  reducers: {
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setPrefix(state, action) {
      state.prefix = action.payload;
    },
    setPrefix2(state, action) {
      state.prefix2 = action.payload;
    },
    setPrefix1(state, action) {
      state.prefix1 = action.payload;
    },
    setCompany(state, action) {
      state.company = action.payload;
    },
    setCompanyType(state, action) {
      state.company_type = action.payload;
      localStorage.setItem("company_type",action.payload)
    },
  },
});

export default Profile.reducer;

export const { setUserName, setCompany,setPrefix1, setPrefix , setCompanyType,setPrefix2} = Profile.actions;

export const selectUserName = (state) => state.profile.userName;
export const selectCompany = (state) => state.profile.company;
export const selectPrefix = (state) => state.profile.prefix;
export const selectPrefix2 = (state) => state.profile.prefix2;
export const selectPrefix1 = (state) => state.profile.prefix1;
export const selectCompanyType = (state) => state.profile.company_type;

