import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { SchemaValidateCompany } from "./schemaValidate";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import logo from "../utility/images/WGLOGO.png";
import { IconButton } from "@mui/material";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlertModal from "../utility/component/modals/alert";
import { useDispatch } from "react-redux";
import {
  setAlertMsg,
  setALertStatus,
  setCheckVerifyEmailAlert,
} from "../utility/Redux/modal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment from "moment";
import { generateHmac } from "../utility/function";
export const PhoneCompnySignup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [isCompanyExist, setCompnyExist] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [companyEmail, setCmpnyEmail] = useState();
  const [allReadyAddedUser, setAllReadyAddedUser] = useState();
  const [companyTypeDetails, setcomTypeDetails] = useState(true);
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const initialValues = {
    company: "",
    group: "",
    email: "",
    address: "",
    city: "",
    state: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    userEmail: "",
    userName: "",
    companyPassword: "",
  };
  function handleEnter(event) {
    if (event.key === "Enter") {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);

      if (index !== 18) {
        form.elements[index + 2].focus();
      } else {
        form.elements[index + 3].focus();
      }

      event.preventDefault();
    }
  }

  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaValidateCompany,
    onSubmit: (values) => {
      if (!isUserExist) {
        const data = {
          user_email: values.userEmail,
          user_password: values.password,
          user_name: values.userName,
          user_type: "ADMIN",
          user_status: "ACTIVE",
          company_email: values.email,
          company_name: values.company,
          company_mobile: values.mobile,
          group_name: values.group,
          address: values.address,
          city: values.city,
          state: values.state,
          start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          end_date: moment()
            .add(30, "days")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        };

        axios
          .post(`/api/users/registerCompany`, data, {
            headers: {
              "Content-Type": "application/json",
    Authorization: `Bearer ${generateHmac(values.userEmail)}`,

            },
          })
          .then(function (response) {
            if (response.data.status === 200) {
              localStorage.clear("rememberd");
              // localStorage.setItem('user',JSON.stringify(values))
              navigate("/auth/login", {
                state: {
                  signupEmail: values.userEmail,
                },
              });
              dispatch(setCheckVerifyEmailAlert(true));
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  });

  const CompanyInfo = () => {
    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: values.email,
          table_prefix :""
        },
        {   headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${generateHmac(values.email)}`,
        }
        }
      )
      .then(async function (response) {
        if (response !== null) {
          const companyDetails = response.data[0];
          values.company = companyDetails.company_name;
          values.mobile = companyDetails.company_mobile;
          values.group = companyDetails.group_name;
          values.city = companyDetails.city;
          values.state = companyDetails.state;
          values.address = companyDetails.address;

          setCompnyExist(true);
          setCmpnyEmail(values.email);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const userInfo = () => {
    axios
      .post(
        `/api/users/userInfo`,
        {
          user_email: values.userEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(values.userEmail)}`,
          }
          }
      )
      .then(async function (response) {
        if (response !== null) {
          const user = response[0];

          dispatch(setAlertMsg("User exist with same email "));
          dispatch(setALertStatus(true));
          values.userEmail = "";

          setAllReadyAddedUser(values.userEmail);
          setUserExist(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(values.email)) {
    
      CompanyInfo();
    }

 
    if (companyEmail !== values.email) {
      setCompnyExist(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (isCompanyExist) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(values.userEmail)) {
       
        userInfo();
      }
    }
    if (allReadyAddedUser !== values.userEmail) {
      setUserExist(false);
    } else {
      setUserExist(true);
    }
  }, [values.userEmail, values.email]);

  return (
    <div className=" items-center flex  px-10 w-screen  h-screen justify-center bg-[#383535]  ">
      <div
        className={`  bg-white  rounded-lg    pb-4  mt-10 mb-10    `}
        style={{ width: "100%" }}
      >
        <div>
          <div className="w-full  items-center flex justify-center ">
            <div className="   -mt-8 bg-white rounded-full   w-20 p:-mt-6 ">
              <img src={logo} alt="" />
            </div>
          </div>
          <div className="flex w-full justify-center text-slate-700 text-2xl font-medium mb-3">
            Sign Up
          </div>
          <div className="bg-slate-200 h-0.5 w-full" />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex-col  t:flex  w-full  ">
            <div className="flex bg-brown py-2 mx-2 justify-center items-center">
              <button
                className="flex w-6/12 justify-center flex:1 "
                onClick={() => setcomTypeDetails(true)}
              >
                <div
                  className={`font-normal tracking-[.06em]   ${
                    companyTypeDetails ? "text-orange" : "text-white "
                  } `}
                >
                  Company Details
                </div>{" "}
              </button>

              <div style={{ width: 2, height: 20 }} className=" bg-white " />
              <button
                className=" flex w-6/12 justify-center flex:1 "
                onClick={() => setcomTypeDetails(false)}
              >
                <div
                  className={` font-normal tracking-[.06em]   text-normal ${
                    companyTypeDetails ? "text-white" : "text-orange"
                  } `}
                >
                  User Details
                </div>
              </button>
            </div>

            {companyTypeDetails ? (
              <div className=" w-full  ">
                <div className="w-full pt-4">
                  <div className="flex w-full">
                    <div className="w-full px-6  ">
                      <div className="flex mt-2  items-center">
                        <div className="w-full">
                          <TextField
                            size={"small"}
                            onKeyDown={handleEnter}
                            type="email"
                            name="email"
                            id="email"
                            label={
                              values.email ? errors.email : "Company Email"
                            }
                            onBlur={handleBlur}
                            error={values.email && errors.email ? true : false}
                            fullWidth
                            // placeholder='Company Email'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.email}
                          />
                        </div>
                      </div>

                      <div className="flex  w-full items-center pt-2 ">
                        <div className=" w-full">
                          <TextField
                            fullWidth
                            type="company"
                            name="company"
                            id="company"
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? "Company Name"
                                : values.company
                                ? errors.company
                                : "Company Name"
                            }
                            error={
                              isCompanyExist
                                ? false
                                : values.company && errors.company
                                ? true
                                : false
                            }
                            //  placeholder="Company Name"
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.company}
                            size={"small"}
                          />
                        </div>
                      </div>

                      <div className="flex mt-2  items-center">
                        <div className="w-full">
                          <TextField
                            size={"small"}
                            onKeyDown={handleEnter}
                            type="group"
                            name="group"
                            id="group"
                            label={
                              isCompanyExist
                                ? "Group Name"
                                : values.group
                                ? errors.group
                                : "Group Name"
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.group && errors.group
                                ? true
                                : false
                            }
                            fullWidth
                            //  placeholder='Your group'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.group}
                          />
                        </div>
                      </div>

                      <div className="flex mt-2  items-center">
                        <div className="w-full">
                          <TextField
                            size={"small"}
                            type="address"
                            name="address"
                            id="address"
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? "Address"
                                : values.address
                                ? errors.address
                                : "Address"
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.address && errors.address
                                ? true
                                : false
                            }
                            fullWidth
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.address}
                          />
                        </div>
                      </div>

                      <div className="flex">
                        <div className="flex mt-2 w-full items-center">
                          <div className=" w-full">
                            <TextField
                              size={"small"}
                              type="city"
                              name="city"
                              onKeyDown={handleEnter}
                              id="city"
                              label={
                                isCompanyExist
                                  ? "City"
                                  : values.city
                                  ? errors.city
                                  : "City"
                              }
                              onBlur={handleBlur}
                              error={
                                isCompanyExist
                                  ? false
                                  : values.city && errors.city
                                  ? true
                                  : false
                              }
                              fullWidth
                              onChange={handleChange}
                              onSubmit={handleSubmit}
                              value={values.city}
                            />
                          </div>
                        </div>

                        <div className="flex mt-2  w-full  ml-1 items-center">
                          <div className="w-full">
                            <TextField
                              size={"small"}
                              type="state"
                              onKeyDown={handleEnter}
                              name="state"
                              id="state"
                              label={
                                isCompanyExist
                                  ? "State, Country"
                                  : values.state
                                  ? errors.state
                                  : "State Country "
                              }
                              onBlur={handleBlur}
                              error={
                                isCompanyExist
                                  ? false
                                  : values.state && errors.state
                                  ? true
                                  : false
                              }
                              fullWidth
                              // placeholder='Your address'
                              onChange={handleChange}
                              onSubmit={handleSubmit}
                              value={values.state}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex mt-2  items-center">
                        <div className="w-full">
                          <TextField
                            size={"small"}
                            type="number"
                            name="mobile"
                            id="mobile"
                            onKeyDown={handleEnter}
                            label={
                              isCompanyExist
                                ? "Company Mobile"
                                : values.mobile
                                ? errors.mobile
                                : "Company Mobile"
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.mobile && errors.mobile
                                ? true
                                : false
                            }
                            fullWidth
                            // placeholder='Company Mobile'
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.mobile}
                          />
                        </div>
                      </div>

                      <div className="flex mt-2 items-center "></div>
                    </div>
                    <div className="w-0.5 bg-slate-200 mt-2 mb-3 " />
                  </div>
                </div>
              </div>
            ) : (
              <div className="grow px-6 ">
                <div className="w-full pt-4">
                  <div className="flex mt-2  items-center">
                    <div className="w-full">
                      <TextField
                        size={"small"}
                        type="email"
                        name="userEmail"
                        onKeyDown={handleEnter}
                        id="userEmail"
                        label={
                          values.userEmail
                            ? isUserExist
                              ? "Already Registerd Email"
                              : errors.userEmail
                            : " User Email"
                        }
                        onBlur={handleBlur}
                        error={
                          values.userEmail
                            ? errors.userEmail || isUserExist
                              ? true
                              : false
                            : false
                        }
                        fullWidth
                        //  placeholder='User Email'
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.userEmail}
                      />
                    </div>
                  </div>

                  <div className="flex w-full items-center pt-2 ">
                    <div className=" w-full">
                      <TextField
                        fullWidth
                        type="userName"
                        name="userName"
                        id="userName"
                        onKeyDown={handleEnter}
                        label={values.userName ? errors.userName : " User Name"}
                        error={
                          values.userName && errors.userName ? true : false
                        }
                        // placeholder="First userName"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.userName}
                        size={"small"}
                      />
                    </div>
                  </div>

                  <div className="flex mt-2 items-center ">
                    <div className="w-full flex">
                      <TextField
                        size={"small"}
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        error={
                          values.password && errors.password ? true : false
                        }
                        label={values.password ? errors.password : "Password"}
                        name="password"
                        onKeyDown={handleEnter}
                        id="password"
                        // placeholder='Password'
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.password}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div className="flex mt-2 w-full items-center">
                    <div className="w-full ">
                      <TextField
                        size={"small"}
                        fullWidth
                        label={
                          values.confirmPassword
                            ? errors.confirmPassword
                            : "Confirm Password"
                        }
                        error={
                          values.confirmPassword && errors.confirmPassword
                            ? true
                            : false
                        }
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        onKeyDown={handleEnter}
                        id="confirmPassword"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className="w-full h-24" />
                </div>
              </div>
            )}

            {/* user details */}
          </div>

          {companyTypeDetails ? (
            <div className="w-full  pt-6  flex justify-center ">
              <button
                type="button"
                onClick={() => setcomTypeDetails(false)}
                className="w-full  flex justify-center "
              >
                <div
                  className=" py-2 rounded-lg bg-[#383535]"
                  style={{ width: "60%" }}
                >
                  <div className="text-white font-bold">Next</div>
                </div>
              </button>
            </div>
          ) : (
            <div className=" flex  pt-6   justify-center ">
              <button
                onClick={() => setcomTypeDetails(true)}
                type="button"
                className=" items-center bg-brown text-white rounded-lg px-2 mr-2 flex justify-center "
              >
                <ArrowBackIosNewIcon />
              </button>
              <button
                className="   flex justify-center "
                style={{ width: "60%" }}
                type="button"
                onClick={() => {
                  if (!values.email || errors.email) {
                    dispatch(setAlertMsg("Please enter company email"));
                    dispatch(setALertStatus(true));
                  } else {
                    if (!values.company || errors.company) {
                      dispatch(setAlertMsg("Please enter company name"));
                      dispatch(setALertStatus(true));
                    } else {
                      if (!values.group || errors.group) {
                        dispatch(setAlertMsg("Please enter group name"));
                        dispatch(setALertStatus(true));
                      } else {
                        if (!values.address || errors.address) {
                          dispatch(setAlertMsg("Please enter company address"));
                          dispatch(setALertStatus(true));
                        } else {
                          if (!values.city || errors.city) {
                            dispatch(setAlertMsg("Please enter city"));
                            dispatch(setALertStatus(true));
                          } else {
                            if (!values.state || errors.state) {
                              dispatch(setAlertMsg("Please enter state"));
                              dispatch(setALertStatus(true));
                            } else {
                              if (!values.mobile || errors.mobile) {
                                dispatch(
                                  setAlertMsg("Please enter company mobile")
                                );
                                dispatch(setALertStatus(true));
                              } else {
                                if (!values.userEmail || errors.userEmail) {
                                  dispatch(
                                    setAlertMsg("Please enter user email")
                                  );
                                  dispatch(setALertStatus(true));
                                } else {
                                  if (!values.userName || errors.userName) {
                                    dispatch(
                                      setAlertMsg("Please enter user name")
                                    );
                                    dispatch(setALertStatus(true));
                                  } else {
                                    if (!values.password || errors.password) {
                                      dispatch(
                                        setAlertMsg("Please enter password")
                                      );
                                      dispatch(setALertStatus(true));
                                    } else {
                                      if (
                                        !values.confirmPassword ||
                                        errors.confirmPassword
                                      ) {
                                        dispatch(
                                          setAlertMsg(
                                            "Please enter confirm password"
                                          )
                                        );
                                        dispatch(setALertStatus(true));
                                      } else {
                                        handleSubmit();
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }}
              >
                <div
                  className=" py-2 rounded-lg bg-[#383535]"
                  style={{ width: "100%" }}
                >
                  <div className="text-white font-bold">SIGN UP</div>
                </div>
              </button>
            </div>
          )}

        </form>
        <AlertModal />

        <div className="w-full flex justify-center pt-2">
          Allready have an account ?
          <div
            onClick={() => navigate("/auth/login")}
            className="text-orange font-medium pl-2"
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};
