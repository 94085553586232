import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import moment from "moment/moment";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix } from "../../../../utility/Redux/profile";
import { setloading } from "../../../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { FormatNumber2 } from "../../../../utility/component/tools";
import { useParams, useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { selectheader } from "../../../../utility/Redux/security";
import DatePicker from 'react-datepicker';
import { parseISO } from 'date-fns';
import DateRangeIcon from '@mui/icons-material/DateRange';
import 'bootstrap/dist/css/bootstrap.min.css';
const columns = [
  {
    field: "SNAME",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <span className="text-black">{"Section Name"} </span>
    ),
  },

  {
    field: "NET_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        if (v.value === 0) {
          return "";
        } else {
          return FormatNumber2(v.value);
        }
      }
    },
    headerClassName: "super-app-theme--header",

    renderHeader: (params) => <div> {"Net Amount"} </div>,
    flex: 1,
  },
];

export const ItemGroupColoum = [
  {
    field: "DATE",
    align: "left",
    valueFormatter: (params) => {
      return moment(params.value).format("YYYY-MM-DD");
    },
    headerAlign: "left",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Date"} </div>,
    width: 100,
  },
  {
    field: "BASIC_AMT",
    headerClassName: "super-app-theme--header",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        if (v.value === 0) {
          return "";
        } else {
          return FormatNumber2(v.value);
        }
      }
    },

    renderHeader: (params) => <div> {"Basic Amt."} </div>,
    flex: 1,
  },
  {
    field: "DISQ_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Discount"} </div>,
    flex: 1,
  },
  {
    field: "SERVICE_CHR",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    align: "right",
    headerAlign: "right",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Service Chr."} </div>,
    flex: 1,
  },

  {
    field: "CGST",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"CGST"} </div>,
    flex: 1,
  },
  {
    field: "SGST",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"SGST"} </div>,
    flex: 1,
  },
  {
    field: "VAT_AMT",
    align: "right",
    headerAlign: "right",
    headerClassName: "super-app-theme--header",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    renderHeader: (params) => <div> {"Vat Amount"} </div>,
    width: 130,
  },
  {
    field: "NET_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      // var amount = params.value.toLocaleString()
      //  var amountWithZero = amount + '.00'
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Net Amount"} </div>,
    flex: 1,
  },
];

const WiseReport = () => {
  const location = useLocation();
  const data = location.state;

  //    const {index,year} = location.state
  const [pageSize, setPageSize] = useState(100);
  const [ItemReport, setItemReport] = useState([]);
  const [ItemList, setItemList] = useState([]);
  const navigate = useNavigate();
  const [options, setOption] = useState(data.list);
  const [total, setTotal] = useState(0);
  const [groupTotal, setGroupTotal] = useState(0);
  const [groupName, setgroupName] = useState(data.type);
  const table = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [years, setyears] = useState("");
  const [sectionListTotal, setSectionListTotal] = useState("");
  //   const firstDateOfSpecificMonth = moment().month(index-1).year(year).startOf('month').format('YYYY-MM-DD');
  //   const lastDateOfSpecificMonth = moment().month(index-1).year(year).endOf('month').format('YYYY-MM-DD');

  const [fromDate, setFromDate] = useState(location.state.from_date);
   const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDate, setToDate] = useState(location.state.to_date);
  
  const [toDateFocus, setToDateFocus] = useState(false);
  const [age, setAge] = useState(data.type);

  const handleChange = (event) => {
    setAge(event.target.value);
    setgroupName(event.target.value);
  };

  const headers = useSelector(selectheader);
  const getItemReport = () => {
    if (data.code === "SNAME") {
      if (groupName) {
        dispatch(setloading(true));
        axios
          .post(
            `/api/salesSubReport/dateWiseSale`,
            {
              table_prefix: table,
              month_name: data.month_name,
              itm_group: data.itm_group,
              outlet_name: data.outlet_name,
              sname: groupName,
              from_date: fromDate,
              to_date: toDate,
            },
            {
              headers: headers,
            }
          )
          .then((d) => {
            let rowList = [];
            let data = d.data;
            var Total = [];
            for (let index = 0; index < data.length; index++) {
              rowList.push({
                ...data[index],
                id: index + 1,
              });
              Total.push(data[index].NET_AMT);
            }
            var Total = Total.map((item) => Number(item))
              .reduce((a, b) => a + b, 0)
              .toLocaleString();

            setTotal(Total + ".00");
            setItemReport(rowList);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setloading(false));
          });
        dispatch(setloading(true));
        axios
          .post(
            `/api/salesReports/sectionWiseSaleReport`,
            {
              table_prefix: table,
              from_date: fromDate,
              to_date: toDate,
            },
            {
              headers: headers,
            }
          )
          .then((d) => {
            let rowList = [];
            let data = d.data;
            let Total = [];
            for (let index = 0; index < data.length; index++) {
              rowList.push({
                SNAME: data[index].SNAME,
                NET_AMT: data[index].NET_AMT,
                id: index + 1,
              });
              Total.push(data[index].NET_AMT);
            }
            var total = Total.map((item) => Number(item)).reduce(
              (a, b) => a + b,
              0
            );
            setSectionListTotal(FormatNumber2(total));
            setOption(rowList);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setloading(false));
          });
      } else {
        dispatch(setloading(true));
        axios
          .post(
            `/api/salesSubReport/sectionItemWiseSale`,
            {
              table_prefix: table,
              from_date: fromDate,
              to_date: toDate,
              sname: "",
            },
            {
              headers: headers,
            }
          )
          .then((d) => {
            let rowList = [];
            let data = d.data;

            // filter

            // for (let index = 0; index < data.length; index++) {
            //   rowList.push({
            //     ...data[index],
            //     id: index + 1,
            //   });
            //   // Total.push(data[index].NET_AMT);
            // }
            // const filterd = rowList.filter((d) => {
            //   if (
            //     moment(moment(d.DATE).format("YYYY-MM-DD")).isBetween(
            //       fromDate,
            //       toDate
            //     ) ||
            //     moment(moment(d.DATE).format("YYYY-MM-DD")).isSame(fromDate) ||
            //     moment(moment(d.DATE).format("YYYY-MM-DD")).isSame(toDate)
            //   ) {
            //     return true;
            //   } else {
            //     return false;
            //   }
            // });

            // let total = [];
            // for (let index = 0; index < filterd.length; index++) {
            //   total.push(filterd[index].NET_AMT);
            // }
            // var Total = total
            //   .map((item) => Number(item))
            //   .reduce((a, b) => a + b, 0)
            //   .toLocaleString();
            // setTotal(Total + ".00");

            // setItemReport(filterd);

            var Total = [];
            for (let index = 0; index < data.length; index++) {
              rowList.push({
                ...data[index],
                id: index + 1,
              });
              Total.push(data[index].NET_AMT);
            }
            var Total = Total.map((item) => Number(item))
              .reduce((a, b) => a + b, 0)
              .toLocaleString();

            setTotal(Total + ".00");
            setItemReport(rowList);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setloading(false));
          });

        dispatch(setloading(true));
        axios
          .post(
            `/api/salesReports/sectionWiseSaleReport`,
            {
              table_prefix: table,
              from_date: fromDate,
              to_date: toDate,
            },
            {
              headers: headers,
            }
          )
          .then((d) => {
            let rowList = [];
            let data = d.data;
            let Total = [];
            for (let index = 0; index < data.length; index++) {
              rowList.push({
                SNAME: data[index].SNAME,
                NET_AMT: data[index].NET_AMT,
                id: index + 1,
              });
              Total.push(data[index].NET_AMT);
            }
            var total = Total.map((item) => Number(item)).reduce(
              (a, b) => a + b,
              0
            );
            setSectionListTotal(FormatNumber2(total));
            setOption(rowList);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            dispatch(setloading(false));
          });
      }
    } else if (data.code === "MONTH_NAME") {
      var y = 2022;

      if (
        moment().format("MMM").includes("Jan") ||
        moment().format("MMM").includes("Feb") ||
        moment().format("MMM").includes("Mar")
      ) {
        if (
          groupName.includes("January") ||
          groupName.includes("February") ||
          groupName.includes("March")
        ) {
          // setyears(moment().format("YYYY"))
          y = moment().format("YYYY");
        } else {
          setyears(moment().format("YYYY") - 1);
          y = moment().format("YYYY") - 1;
        }
      } else {
        if (
          groupName.includes("January") ||
          groupName.includes("February") ||
          groupName.includes("March")
        ) {
          setyears(moment().format("YYYY") - 1);
          y = moment().format("YYYY") - 1;
        } else {
          setyears(moment().format("YYYY"));
          y = moment().format("YYYY");
        }
      }
      dispatch(setloading(true));

      axios
        .post(
          `/api/salesSubReport/dateWiseSale`,
          {
            table_prefix: table,
            month_name: groupName,
            itm_group: data.itm_group,
            outlet_name: data.outlet_name,
            sname: data.sname,
            from_date: moment()
              .month(groupName)
              .year(y)
              .startOf("month")
              .format("YYYY-MM-DD"),
            to_date: moment()
              .month(groupName)
              .year(y)
              .endOf("month")
              .format("YYYY-MM-DD"),
          },
          {
            headers: headers,
          }
        )
        .then((d) => {
          let rowList = [];
          let data = d.data;

          var Total = [];
          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
            Total.push(data[index].NET_AMT);
          }
          var Total = Total.map((item) => Number(item))
            .reduce((a, b) => a + b, 0)
            .toLocaleString();

          setTotal(Total + ".00");
          setItemReport(rowList);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          dispatch(setloading(false));
        });
    } else {
      console.log("not match ");
    }
  };

  useEffect(() => {
    getItemReport();
  }, [fromDate, toDate, groupName]);

  return (
    <div className="flex  bg-slate-50 h-full  w-full  p:pt-8 pt-14   ">
      {data?.code === "SNAME" && (
        <div className="w-1/4 d:w-1/4 l:w-1/4  h-5/6 p-1 pt-4">
          <Box
            sx={{
              height: "70%",
              flexDirection: "row",
              flex: 1,
              "& .super-app-theme--header": {
                backgroundColor: "#E5E7EB",
              },
            }}
          >
            <DataGrid
              hideFooter
              rowsPerPageOptions={[100, 500, 1000, 1500]}
              sx={{ backgroundColor: "white" }}
              pageSize={100}
              rowHeight={34}
              onRowClick={(data) => {
                setgroupName(data.row.SNAME);
              }}
              showCellRightBorder
              showColumnRightBorder
              editMode={"row"}
              experimentalFeatures={{ newEditingApi: true }}
              rows={options}
              columns={columns}
            />
          </Box>

          <div className="flex t:flex-col items-center mt-3 w-full d:flex-row    px-1">
            <div className="flex-1  t:w-full  t:flex t:my-2 t:justify-end d:justify-start t:order-8 d:order-none">
              <Button
                sx={{
                  backgroundColor: "#3595f0",
                  letterSpacing: "1px",
                  textTransform: "none",
                  height: "38px",
                  width:"20%"
                }}
                variant="contained"
                size="large"
              >
                <span > Print</span>
              </Button>
            </div>

            <div className="flex   t:w-full t:justify-end">
              <div className="font-medium flex items-center pr-1">Total : </div>
              <div className="pl-2 font-bold">{sectionListTotal + "/-"}</div>
            </div>
          </div>
        </div>
      )}

      {/* second  */}

      <div className="flex-1  bg-slate-50 pb-2 px-1  pt-3 ">
        <div className="flex flex-col  h-full">
          <div className="flex ">
            <div className="flex w-full ">
              <div
                id="date"
                className="max-sm:block t:flex  py-2 pl-0 "
                style={window.innerWidth <= 1024  ? { width: '60%'} : { width: '40%'}}
              >
                <div
                  className="flex xl:p-3 max-sm:p-1.5 t:p-2"
                  style={
                   {
                          width: '100%',
                          border: '1px solid #bdbdbd',
                          borderRadius:"7px",
                        }
                  }
                >
                  <div
                    style={{ width: '26%' }}
                    className="xl:flex xl:text-md max-sm:hidden t:hidden xl:items-center text-[#4a4a4a]"
                  >
                    From Date
                  </div>
                  
                  <div
                    style={
                       {
                            height: '40px',
                            width: '50%',
                            border: '1px solid #bdbdbd',
                            borderTopLeftRadius: '7px',
                            borderBottomLeftRadius: '7px',
                          }
                    }
                    className="flex w-full items-center xl:ml-1 "
                  >
                    <DatePicker
                      onFocus={() => setFromDateFocus(true)}
                      onClickOutside={() => setFromDateFocus(false)}
                      onSelect={() => setFromDateFocus(false)}
                      open={fromDateFocus}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(fromDate).format('YYYY-MM-DD'))}
                      onChange={(fromDate) => {
                        setFromDate(moment(fromDate).format('YYYY-MM-DD'));
                      }}
                    />
                  </div>

                  <div
                    style={
                     {
                            height: '40px',
                            width: '14%',
                            border: '1px solid #bdbdbd',
                            borderLeft: '0px',
                            borderTopRightRadius: '7px',
                        borderBottomRightRadius: '7px',
                          }
                    }
                    className="items-center flex justify-center"
                  >
                    <DateRangeIcon style={{ color: "gray" }} onClick={() => { setFromDateFocus(true) }} />
                  </div>

                  <div
                    style={{ width: '15%' }}
                    className="flex justify-center items-center xl:text-md max-sm:text-xs t:text-xs text-[#4a4a4a]"
                  >
                    To
                  </div>

                  <div
                    style={
                       {
                            height: '40px',
                            width: '50%',
                            border: '1px solid #bdbdbd',
                            borderTopLeftRadius: '7px',
                            borderBottomLeftRadius: '7px',
                          }
                    }
                    className=" flex w-full items-center xl:ml-1"
                  >
                    <DatePicker
                      onFocus={() => setToDateFocus(true)}
                      onClickOutside={() => setToDateFocus(false)}
                      onSelect={() => setToDateFocus(false)}
                      open={toDateFocus}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(toDate).format('YYYY-MM-DD'))}
                      onChange={(toDate) => {
                        setToDate(moment(toDate).format('YYYY-MM-DD'));
                      }}
                    />
                  </div>

                  <div
                    style={
                     {
                            height: '40px',
                            width: '14%',
                            border: '1px solid #bdbdbd',
                            borderLeft: '0px',
                            borderTopRightRadius: '7px',
                        borderBottomRightRadius: '7px',
                            
                          }
                    }
                    className="items-center flex justify-center"
                  >
                   <DateRangeIcon style={{ color: "gray" }} onClick={() => { setToDateFocus(true) }} />
                  </div>
                </div>
                
              </div>


              <div style={{width:"50%"}} className="flex  justify-start items-center">
                 {data.code === "SNAME" ? (
                <div className=" w-full  flex ">
                  <div className="flex  justify-start items-center w-full">
                    {/* <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small">
                        {data.label}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={groupName ? groupName : "All"}
                        label={data.label}
                        onChange={handleChange}
                        sx={{ fontSize: 13, textTransform: "revert-layer" }}
                      >
                        <MenuItem value="All">All</MenuItem>
                        {options.map((d, index) => {
                          if (data.code == "MONTH_NAME") {
                            return (
                              <MenuItem value={d.MONTH_NAME}>
                                {d.MONTH_NAME}
                              </MenuItem>
                            );
                          } else if (data.code == "SNAME") {
                            return (
                              <MenuItem value={d.SNAME}>{d.SNAME}</MenuItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Select>
                    </FormControl> */}
                    <div className="flex items-center">
                      <div className="bg-[#e8edf6] rounded-lg max-w-max  flex justify-between items-center  px-1 py-1 ml-1">
                        <span
                          style={{ fontSize: 15 }}
                          className={`text-black font-normal ${
                            groupName === "" ? "pr-10" : null
                          } `}
                        >
                          {" "}
                          {groupName === "" ? "All " : groupName}
                        </span>
                        <div
                          className={`${
                            groupName === "" ? "hidden" : "flex"
                          } px-1`}
                        >
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" w-full pt-2 flex ">
                  <div className="flex  justify-between w-full">
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small">
                        {data.label}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={groupName}
                        label={data.label}
                        onChange={handleChange}
                        sx={{ fontSize: 13, textTransform: "revert-layer" }}
                      >
                        {options.map((d, index) => {
                          if (data.code == "MONTH_NAME") {
                            return (
                              <MenuItem value={d.MONTH_NAME}>
                                {d.MONTH_NAME}
                              </MenuItem>
                            );
                          } else if (data.code == "SNAME") {
                            return (
                              <MenuItem value={d.SNAME}>{d.SNAME}</MenuItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              </div>
            </div>
          </div>

          <div className=" h-64 grow  ">
            <Box
              sx={{
                backgroundColor: "white",
                "& .super-app-theme--header": {
                  backgroundColor: "#E5E7EB",
                },
                height: "100%",
              }}
            >
              <DataGrid
                rowsPerPageOptions={[25, 50, 75, 100]}
                sx={{ backgroundColor: "white", fontSize: 14 }}
                onPageSizeChange={(size) => setPageSize(size)}
                pageSize={pageSize}
                rowHeight={34}
                showCellRightBorder
                showColumnRightBorder
                editMode={"row"}
                experimentalFeatures={{ newEditingApi: true }}
                rows={ItemReport}
                columns={ItemGroupColoum}
              />
            </Box>
          </div>

          <div className="  w-full   py-1 pt-2 px-1 p:flex justify-end">
            <div className="flex  flex-1 hidden p:flex">
              <Button
                variant="contained"
                sx={{
                  marginRight: "2px",
                  backgroundColor: "#3595f0",
                  letterSpacing: "1px",
                  textTransform: "none",
                  height: "40px",
                  width:"8%"
                }}
              >
                <div >
                  Print
                </div>
              </Button>
              <Button
                sx={{
                  marginRight: "3px",
                  letterSpacing: "1px",
                  textTransform: "none",
                  backgroundColor: "#3595f0",
                  height: "40px",
                  width:"8%"
                }}
                variant="contained"
              >
                <div > Email</div>
              </Button>
              <Button
                sx={{ letterSpacing: "1px", textTransform: "none" , height: "40px",
                  width:"8%" }}
                color="gray2"
                onClick={() => navigate("/")}
                variant="contained"
              >
                <div > Exit</div>
              </Button>
            </div>

            <div className="flex items-center">
              <div className="font-medium flex-1 pr-1 ">Total :</div>
              <div className="pl-2 font-bold">{total + "/-"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WiseReport;
