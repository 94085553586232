import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { FormatNumber2 } from "../../../utility/component/tools";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { setloading } from "../../../utility/Redux/modal";
import { selectPrefix } from "../../../utility/Redux/profile";
import { SelectDate } from "../../../utility/Redux/refresh";
import { IoCalendarClearSharp } from "react-icons/io5";
import { selectheader } from "../../../utility/Redux/security";

const WeeklySale = () => {
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();
  const checkDateData = useSelector(SelectDate);
  const [WeeklyReport, SetWeeklyReport] = useState([]);
  const [dateList, SetDateList] = useState([]);
  const table = useSelector(selectPrefix);

  const headers = useSelector(selectheader);
  const getWeeklyReport = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/salesReports/weeklySaleReport`,
        {
          table_prefix: table,
          from_date: moment(checkDateData).startOf("week").format("YYYY-MM-DD"),
          to_date: moment(checkDateData).endOf("week").format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        const startDate = moment(checkDateData).startOf("week");
        const endDate = moment(checkDateData).endOf("week");

        // Create an array of dates for the week
        const dates = [];
        let currentDate = startDate;
        while (currentDate <= endDate) {
          dates.push(currentDate.date());
          currentDate = currentDate.clone().add(1, "day");
        }
        SetDateList(dates);
        SetWeeklyReport(data);
        var t = 0;
        for (let i = 0; i < data.length; i++) {
          t = t + data[i].NET_AMT;
        }
        setTotal(t);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getWeeklyReport();
  }, [checkDateData]);

  // setTotal(t)

  return (
    <div className="ml-1 pb-4   ">
      <div
        style={{ fontSize: 16 }}
        className="flex text-lg text-brown font-bold"
      >
        Weekly Sale(s)
      </div>
      <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 ">
        {WeeklyReport[0]
          ? WeeklyReport.map((d, index) => {
              return (
                <div
                  key={index}
                  style={{
                    boxShadow:'1px 1px 1px gray'
                  }}
                  className="flex d:flex  bg-[#f8e4dd]  rounded-lg   "
                >
                  <div className=" p-2 w-full ">
                    <div className="  flex justify-between items-center ">
                      <div className="grow flex ">
                        <div className="text-black font-medium flex grow ">
                          {d.DAY_NAME}
                        </div>
                        <div>
                          <svg
                            width={28}
                            height={28}
                            className={"flex justify-center items-center  "}
                          >
                            {/* Render the calendar cells */}

                            <IoCalendarClearSharp
                              x={0}
                              y={0}
                              height={26}
                              size={26}
                              strokeWidth="20"
                              width={30}
                              fill={'white'}
                              stroke={"black"}
                            />

                            {/* Fill the 15th day */}
                            <text
                              x={7}
                              y={20}
                              fill={'black'}
                              style={{
                                fontSize: 11,

                                color: "white",
                              }}
                            >
                              {dateList[index]}
                            </text>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{ whiteSpace: "nowrap" }}
                      className="text-black  font-normal text-base pl-1 flex  "
                    >
                      ₹ {FormatNumber2(d.NET_AMT)}
                      /-
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        <div 
        style={{
          boxShadow:'1px 1px 1px gray'
        }}
        className=" p-2 bg-[#383535] rounded-lg  ">
          <span className=" font-medium text-green">Total</span>
          <div
            style={{ whiteSpace: "nowrap" }}
            className=" font-normal text-green text-base pl-1 flex  "
          >
            ₹ {FormatNumber2(total)}
            /-
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeeklySale;
