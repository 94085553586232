import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix, selectPrefix1, selectPrefix2, setPrefix } from "../../utility/Redux/profile";
import GarmentDashboard from "./garmentDashboard2";


function Dahboard() {
const [tabScreen,setTabScreen]= useState(1)
const prefix2 = useSelector(selectPrefix2)
const prefix = useSelector(selectPrefix)
const prefix1 = useSelector(selectPrefix1)
const dispatch = useDispatch()
  return (
    <div className="pb-4 pt-12 px-1">
   <div className="h-full flex flex-col  ">
            <div
              
              className=" w-full  rounded-sm flex "
            >
              <div
                onClick={() => {
                  setTabScreen(1);
                  dispatch(setPrefix(prefix1))
                }}
                style={{
                  color: tabScreen === 1 ? "white" : "gray",
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 1
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9]  bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-l-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 1 ? 600 : 400,
                  }}
                  className=" h-full flex items-center uppercase"
                >
          {prefix1?.replace(/_(.*?)_/g, "")}
                </div>
              </div>
              {prefix2 &&       <div
                style={{
                  color: tabScreen === 2 ? "white" : "gray",
                }}
                onClick={() => {
                  setTabScreen(2);
                  dispatch(setPrefix(prefix2))

                  
                }}
                className={`flex-1 p-2 ${
                  tabScreen === 2
                    ? "bg-[#0d0d0d] "
                    : " hover:bg-[#f1f5f9] bg-[#ebe8e8] hover:cursor-pointer "
                }  flex justify-center items-center rounded-r-sm `}
              >
                <div
                  style={{
                    fontSize: "16px",
                    fontWeight: tabScreen === 2 ? 600 : 400,
                  }}
                  className="  flex items-center uppercase"
                >
                     {prefix2.replace(/_(.*?)_/g, "")}

                </div>
              </div>}
        
            </div>

            <div className=" flex flex-col overflow-y-auto overflow-x-hidden ">
              <div className="h-full">
                {tabScreen === 1 && (
                  <div
                    className=" h-full text-black   flex-col l:flex l:flex-row     "
                  >
           <GarmentDashboard />
                  </div>
                )}
                {tabScreen === 2 && (
              <GarmentDashboard />

                )}
              </div>
            </div>
          </div>
    </div>
  );
}

export default Dahboard;
