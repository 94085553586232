import React, { useEffect} from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../images/ico.png";
import {
  selectCustomeTwoButtomAlert,
  setCustomeTwoButtomAlert,
} from "../../Redux/modal";
import { setLogin, setToken } from "../../Redux/security";
import { useNavigate } from "react-router-dom";

export default function CustomeTwoButtonAlert() {
  const dispatch = useDispatch();
  const status = useSelector(selectCustomeTwoButtomAlert);

  const navigate = useNavigate();
  const myFunction = () => {
    if (status) {
      dispatch(setToken(""));
      dispatch(setLogin(false));
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();

        // 👇️ your logic here
        if (status) {
          myFunction();
        }
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [status]);

  const handleClose = () => {
    dispatch(setCustomeTwoButtomAlert(false));
  };

  const currentPath = window.location.pathname;

  let newPath = '';

if (currentPath === "/sale_desk_rooms") {
  newPath = "/sale_desk_rooms"; // Set the path based on Condition 1
} else if (currentPath === "/item_group") {
  newPath = "/item_group"; // Set the path based on Condition 2
} else {
  newPath = "/home"; // Set a default path if no conditions are met
}

  return (
    <div className=" rounded-lg">
      <Modal open={status} onClose={handleClose}>
        <div
          className="bg-brown overflow-hidden rounded-lg justify-center  "
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",

            divShadow: 24,
          }}
        >
          <div className=" text-white flex justify-start pl-3 font-normal py-2  bg-[#262424] drop-shadow-xl">
            genius office
          </div>
          <div className="justify-center bg-white min-h-32 pt-6 items-center ">
            <div className="flex  w-full px-6 pb-6  items-center ">
              <img src={logo} className="mr-3 w-10 h-10 " alt=" " />
              <div>
                <div className="text-brown text-md ">
                  Are you sure you want to log out from salesDesk ?
                </div>
              </div>
            </div>
            <div className="flex justify-end pb-3  bg-white pr-3 ">
              <div className="px-2">
                <Button
                  size={"small"}
                  onClick={() => {
                    dispatch(setToken(""));
                    dispatch(setLogin(false));
                    navigate("/auth/login");
                    localStorage.removeItem("expiresAt");
                    localStorage.removeItem("prefix");
                    localStorage.removeItem("company");
                    localStorage.removeItem("userName");
                    if (newPath === '/home') {
                      localStorage.setItem("lastVisitedPath",'/'); 
                    } else {
                      localStorage.setItem("lastVisitedPath", newPath);  
                    }
                    
                    dispatch(setCustomeTwoButtomAlert(false));
                  }}
                  color="secondary"
                  variant="contained"
                  sx={{ transition: "none", textTransform: "capitalize" }}
                >
                  <div className="px-2">Logout </div>{" "}
                </Button>
              </div>

              
              <div>
                <Button
                  size={"small"}
                  onClick={handleClose}
                  color="gray"
                  variant="contained"
                  sx={{ transition: "none", textTransform: "capitalize" }}
                >
                  <div className="px-2">Cancel </div>{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
