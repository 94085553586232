import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { SchemaValidateCompany } from "./schemaValidate";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import logo from "../utility/images/WGLOGO.png";
import { IconButton } from "@mui/material";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AlertModal from "../utility/component/modals/alert";
import { useDispatch, useSelector } from "react-redux";
import {
  selectStatus,
  setAlertMsg,
  setALertStatus,
  setCheckVerifyEmailAlert,
  setloading,
} from "../utility/Redux/modal";
import { useFocus } from "../utility/hooks/hooks";
import { BarLoader } from "react-spinners";
import moment from "moment";
import { generateHmac } from "../utility/function";

export const DesktopCompnySignup = () => {
  const override = {
    display: "block",
    margin: "0 auto",
    top: 0,
    width: "auto",
  };
  const inputprops = {
    sx: {
      fontSize: '14px',
      backgroundColor: 'white',
      // Adjust the font size as per your preference
      '& .MuiOutlinedInput-notchedOutline': {
        // Remove the default outline color
        border: '0.5px solid #6e6d6b',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: '#03a9f4', // Change to your desired color on hover
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '0.1px solid #03a9f4', // Change to your desired color on focus
      },
    },
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [isCompanyExist, setCompnyExist] = useState(false);
  const [isUserExist, setUserExist] = useState(false);
  const [companyEmail, setCmpnyEmail] = useState();
  const [allReadyAddedUser, setAllReadyAddedUser] = useState();
  const [compnyEmailRef, setcompnyEmailFocus] = useFocus();
  const loading = useSelector(selectStatus);


  function handleEnter(event) {
    if (event.key === "Enter") {
      const form = event.target.form;

      const index = Array.prototype.indexOf.call(form, event.target);
      if (index !== 18) {
        form.elements[index + 2].focus();
      } else {
        form.elements[index + 3].focus();
      }

      event.preventDefault();
    }
  }
  const initialValues = {
    company: "",
    group: "",
    email: "",
    address: "",
    city: "",
    state: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    userEmail: "",
    userName: "",
    companyPassword: "",
  };
  useEffect(() => {
    const timer = setTimeout(() => setShowPassword(false), 1000);
    return () => clearTimeout(timer);
  }, [showPassword]);

  const { values, errors, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: SchemaValidateCompany,
    onSubmit: (values) => {
      if (!isUserExist) {
        const data = {
          user_email: values.userEmail,
          user_password: values.password,
          user_name: values.userName,
          user_type: "ADMIN",
          user_status: "ACTIVE",
          company_email: values.email,
          company_name: values.company,
          company_mobile: values.mobile,
          group_name: values.group,
          address: values.address,
          city: values.city,
          state: values.state,
          start_date: moment().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          end_date: moment()
            .add(30, "days")
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        };

        axios
          .post(`/api/users/registerCompany`, data, {
            headers: {
              "Content-Type": "application/json",
    Authorization: `Bearer ${generateHmac(values.userEmail)}`,

            },
          })
          .then(function (response) {
            if (response.data.status === 200) {
              // localStorage.setItem('user',JSON.stringify(values))
              localStorage.clear("rememberd");
              navigate("/auth/login", {
                state: {
                  signupEmail: values.userEmail,
                },
              });
              dispatch(setCheckVerifyEmailAlert(true));
            } else {
              alert(response.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
  });

  const CompanyInfo = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/users/companyInfo`,
        {
          company_email: values.email,
          table_prefix :""
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(values.email)}`,
          }
        }
      )
      .then(async function (response) {
        if (response !== null) {
          const companyDetails = response.data[0];
          values.company = companyDetails.company_name;
          values.mobile = companyDetails.company_mobile;
          values.group = companyDetails.group_name;
          values.city = companyDetails.city;
          values.state = companyDetails.state;
          values.address = companyDetails.address;

          setCompnyExist(true);
          setCmpnyEmail(values.email);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const userInfo = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/users/userInfo`,
        {
          user_email: values.userEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(values.userEmail)}`,
          }
        }
      )
      .then(async function (response) {
        if (response !== null) {
          const user = response[0];
          dispatch(setAlertMsg("User exist with same email "));
          dispatch(setALertStatus(true));
          values.userEmail = "";
          setAllReadyAddedUser(values.userEmail);
          setUserExist(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(values.email)) {
      CompanyInfo();
    }
    if (companyEmail !== values.email) {
      setCompnyExist(false);
    }
  }, [values.email]);

  useEffect(() => {
    if (isCompanyExist) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(values.userEmail)) {
        userInfo();
      }
    }
    if (allReadyAddedUser !== values.userEmail) {
      setUserExist(false);
    } else {
      setUserExist(true);
    }
  }, [values.userEmail, values.email]);

  return (
    <div className=" items-center flex  px-10 w-screen   h-screen  justify-center bg-white  ">
      <div
        className={`  bg-white   rounded-lg t:h-6/12  d:w-6/12 t:w-8/12  pb-4  mt-10 mb-10  `}
      >
        <div className=" rounded-lg">
          <div className="w-full  items-center flex justify-center ">
            <div className="  t:w-20 w-20   -mt-6 bg-white rounded-full  l:w-20 l:-mt-6 d:w-32 ">
              <img src={logo} alt="" />
            </div>
          </div>
          <div
            style={{ fontSize: 20 }}
            className="flex w-full justify-center text-black pt-1 pb-3 font-medium"
          >
            Sign Up
          </div>
        
          <BarLoader
            color={"#F5874F"}
            loading={loading}
            cssOverride={override}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={1}
          />
        </div>

        <form onSubmit={handleSubmit}>
          <div className="flex w-full   ">
            {/* company details */}
            <div className="flex-1  ">
              <div
                style={{ fontSize: 15, fontWeight: "600" }}
                className="flex justify-center text-black   pt-2"
              >
                Company Details
              </div>
              <div className="w-full  flex flex-col  justify-start h-full ">
                <div className="flex w-full">
                  <div className="w-full px-6  ">
                    <div
                      style={{ height: "32px" }}
                      className="flex mt-4 t:mt-3  items-center"
                    >
                      <TextField
                        onKeyDown={handleEnter}
                        InputProps={{
                          style: {
                            fontSize: "14",
                            height: 32,

                            justifyContent: "center",
                          },
                        }}
                        type="email"
                        name="email"
                        id="email"
                        label={values.email ? errors.email : null}
                        onBlur={handleBlur}
                        error={errors.email ? true : false}
                        fullWidth
                        placeholder="Company email"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.email}
                      />
                    </div>

                    <div
                      style={{ height: "32px" }}
                      className="flex  w-full items-center mt-4 t:mt-3 "
                    >
                      <TextField
                        fullWidth
                        type="company"
                        name="company"
                        id="company"
                        InputProps={{
                          style: {
                            fontSize: "14",
                            height: 32,

                            justifyContent: "center",

                            placeholder: {
                              color: "red",
                              fontSize: "14px",
                            },
                          },
                        }}
                        onKeyDown={handleEnter}
                        label={
                          isCompanyExist
                            ? null
                            : values.company
                            ? errors.company
                            : null
                        }
                        error={
                          isCompanyExist
                            ? false
                            : values.company && errors.company
                            ? true
                            : false
                        }
                        placeholder="Company name"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.company}
                        size={"small"}
                      />
                    </div>

                    <div
                      style={{ height: "32px" }}
                      className="flex mt-4 t:mt-3 items-center"
                    >
                      <TextField
                        size={"small"}
                        onKeyDown={handleEnter}
                        type="group"
                        name="group"
                        id="group"
                        InputProps={{
                          style: {
                            fontSize: "14",
                            height: 32,

                            justifyContent: "center",

                            placeholder: {
                              color: "red",
                              fontSize: "14px",
                            },
                          },
                        }}
                        label={
                          isCompanyExist
                            ? null
                            : values.group
                            ? errors.group
                            : null
                        }
                        onBlur={handleBlur}
                        error={
                          isCompanyExist
                            ? false
                            : values.group && errors.group
                            ? true
                            : false
                        }
                        fullWidth
                        placeholder="Your group"
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        value={values.group}
                      />
                    </div>

                    <div
                      style={{ height: "32px" }}
                      className="flex mt-4 t:mt-3  items-center"
                    >
                      <div className="w-full">
                        <TextField
                          size={"small"}
                          type="address"
                          name="address"
                          id="address"
                          InputProps={{
                            style: {
                              fontSize: "14",
                              height: 32,

                              justifyContent: "center",

                              placeholder: {
                                color: "red",
                                fontSize: "14px",
                              },
                            },
                          }}
                          onKeyDown={handleEnter}
                          label={
                            isCompanyExist
                              ? null
                              : values.address
                              ? errors.address
                              : null
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.address && errors.address
                              ? true
                              : false
                          }
                          fullWidth
                          placeholder="Your address"
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.address}
                        />
                      </div>
                    </div>

                    <div className="flex">
                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3 w-full items-center"
                      >
                        <div className=" w-full">
                          <TextField
                            size={"small"}
                            type="city"
                            InputProps={{
                              style: {
                                fontSize: "14",
                                height: 32,

                                justifyContent: "center",

                                placeholder: {
                                  color: "red",
                                  fontSize: "14px",
                                },
                              },
                            }}
                            name="city"
                            onKeyDown={handleEnter}
                            id="city"
                            label={
                              isCompanyExist
                                ? null
                                : values.city
                                ? errors.city
                                : null
                            }
                            onBlur={handleBlur}
                            error={
                              isCompanyExist
                                ? false
                                : values.city && errors.city
                                ? true
                                : false
                            }
                            fullWidth
                            placeholder="City"
                            onChange={handleChange}
                            onSubmit={handleSubmit}
                            value={values.city}
                          />
                        </div>
                      </div>

                      <div
                        style={{ height: 32 }}
                        className="flex mt-4 t:mt-3  w-full  ml-1 items-center"
                      >
                        <TextField
                          size={"small"}
                          type="state"
                          onKeyDown={handleEnter}
                          InputProps={{
                            style: {
                              fontSize: "14",
                              height: 32,

                              justifyContent: "center",

                              placeholder: {
                                color: "red",
                                fontSize: "14px",
                              },
                            },
                          }}
                          name="state"
                          id="state"
                          label={
                            isCompanyExist
                              ? null
                              : values.state
                              ? errors.state
                              : null
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.state && errors.state
                              ? true
                              : false
                          }
                          fullWidth
                          placeholder="Country"
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.state}
                        />
                      </div>
                    </div>

                    <div className="flex mt-4 t:mt-3  items-center">
                      <div style={{ height: 32 }} className="w-full">
                        <TextField
                          size={"small"}
                          type="number"
                          name="mobile"
                          id="mobile"
                          InputProps={{
                            style: {
                              fontSize: "14",
                              height: 32,

                              justifyContent: "center",

                              placeholder: {
                                color: "red",
                                fontSize: "14px",
                              },
                            },
                          }}
                          onKeyDown={handleEnter}
                          label={
                            isCompanyExist
                              ? null
                              : values.mobile
                              ? errors.mobile
                              : null
                          }
                          onBlur={handleBlur}
                          error={
                            isCompanyExist
                              ? false
                              : values.mobile && errors.mobile
                              ? true
                              : false
                          }
                          fullWidth
                          placeholder="Company Mobile"
                          onChange={handleChange}
                          onSubmit={handleSubmit}
                          value={values.mobile}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div
                    style={{ width: 1, backgroundColor: "#e0e0e0" }}
                    className=" py-10 h-full "
                  /> */}
                </div>
              </div>
            </div>

            {/* user details */}
            <div style={{
              borderLeft:'1px solid #e0e0e0'
            }} className="flex-1  flex-col  ">
            <div className="flex flex-col h-full justify-between ">
             <div>
              <div
                style={{ fontSize: 15, fontWeight: "600" }}
                className="flex justify-center text-black  mt-2"
              >
                User Details
              </div>

              <div className="w-full  flex flex-col justify-start  px-4 ">
               
                  <div style={{ height: 32 }}   className="flex mt-4 t:mt-3 w-full items-center">
                    <TextField
                      size={"small"}
                      InputProps={{
                        style: {
                          fontSize: "14",
                          height: 32,

                          justifyContent: "center",

                          placeholder: {
                            color: "red",
                            fontSize: "14px",
                          },
                        },
                      }}
                      type="email"
                      name="userEmail"
                      onKeyDown={handleEnter}
                      id="userEmail"
                      label={
                        values.userEmail
                          ? isUserExist
                            ? null
                            : errors.userEmail
                          : null
                      }
                      onBlur={handleBlur}
                      error={
                        values.userEmail
                          ? errors.userEmail || isUserExist
                            ? true
                            : false
                          : false
                      }
                      fullWidth
                      placeholder="User email"
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      value={values.userEmail}
                    />
                  </div>
            

                <div className="flex w-full items-center ">
                  <div style={{ height: 32 }} className=" w-full mt-4 t:mt-3">
                    <TextField
                      fullWidth
                      type="userName"
                      name="userName"
                      id="userName"
                      InputProps={{
                        style: {
                          fontSize: "14",
                          height: 32,

                          justifyContent: "center",

                          placeholder: {
                            color: "red",
                            fontSize: "14px",
                          },
                        },
                      }}
                      onKeyDown={handleEnter}
                      label={values.userName ? errors.userName : null}
                      error={values.userName && errors.userName ? true : false}
                      placeholder="First username"
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      value={values.userName}
                      size={"small"}
                    />
                  </div>
                </div>

                <div className="flex mt-4 t:mt-3 items-center ">
                  <div style={{ height: 32 }} className="w-full flex">
                    <TextField
                      size={"small"}
                      type={showPassword ? "text" : "password"}
                      fullWidth
                      error={values.password && errors.password ? true : false}
                      label={values.password ? errors.password : null}
                      name="password"
                      onKeyDown={handleEnter}
                      id="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "14",
                          height: 32,

                          justifyContent: "center",

                          placeholder: {
                            color: "red",
                            fontSize: "14px",
                          },
                        },
                      }}
                      placeholder="Password"
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      value={values.password}
                    />
                  </div>
                </div>

                <div className="flex mt-4 t:mt-3 w-full items-center">
                  <div style={{ height: 32 }} className="w-full ">
                    <TextField
                      size={"small"}
                      fullWidth
                      label={
                        values.confirmPassword ? errors.confirmPassword : null
                      }
                      error={
                        values.confirmPassword && errors.confirmPassword
                          ? true
                          : false
                      }
                      type={showPassword ? "text" : "password"}
                      name="confirmPassword"
                      onKeyDown={handleEnter}
                      id="confirmPassword"
                      placeholder="Confirm password"
                      onChange={handleChange}
                      onSubmit={handleSubmit}
                      value={values.confirmPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ marginLeft: 1 }}
                          >
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "14",
                          height: 32,

                          justifyContent: "center",

                          placeholder: {
                            color: "red",
                            fontSize: "14px",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                </div>
                </div>
                <div className="w-full px-4  ">
            <button
              type="button"
              onClick={() => {
                if (!values.email || errors.email) {
                  dispatch(setAlertMsg("Please enter company email"));
                  dispatch(setALertStatus(true));
                } else {
                  if (!values.company || errors.company) {
                    dispatch(setAlertMsg("Please enter company name"));
                    dispatch(setALertStatus(true));
                  } else {
                    if (!values.group || errors.group) {
                      dispatch(setAlertMsg("Please enter group name"));
                      dispatch(setALertStatus(true));
                    } else {
                      if (!values.address || errors.address) {
                        dispatch(setAlertMsg("Please enter company address"));
                        dispatch(setALertStatus(true));
                      } else {
                        if (!values.city || errors.city) {
                          dispatch(setAlertMsg("Please enter city"));
                          dispatch(setALertStatus(true));
                        } else {
                          if (!values.state || errors.state) {
                            dispatch(setAlertMsg("Please enter state"));
                            dispatch(setALertStatus(true));
                          } else {
                            if (!values.mobile || errors.mobile) {
                              dispatch(
                                setAlertMsg("Please enter company mobile")
                              );
                              dispatch(setALertStatus(true));
                            } else {
                              if (!values.userEmail || errors.userEmail) {
                                dispatch(
                                  setAlertMsg("Please enter user email")
                                );
                                dispatch(setALertStatus(true));
                              } else {
                                if (!values.userName || errors.userName) {
                                  dispatch(
                                    setAlertMsg("Please enter user name")
                                  );
                                  dispatch(setALertStatus(true));
                                } else {
                                  if (!values.password || errors.password) {
                                    dispatch(
                                      setAlertMsg("Please enter password")
                                    );
                                    dispatch(setALertStatus(true));
                                  } else {
                                    if (
                                      !values.confirmPassword ||
                                      errors.confirmPassword
                                    ) {
                                      dispatch(
                                        setAlertMsg(
                                          "Please enter confirm password"
                                        )
                                      );
                                      dispatch(setALertStatus(true));
                                    } else {
                                      handleSubmit();
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }}
              className="w-full  flex justify-center "
            >
              <div
                className=" py-2 rounded-lg bg-[#383535]"
                style={{ width: "100%" }}
              >
                <div className="text-white font-semibold">
                  <div> Sign Up</div>
                </div>
              </div>
            </button>
          </div>
            
              
          

              </div>
            </div>


          </div>

       
          {/* <div className='w-full flex justify-center pt-2 '>
            <Button type='submit'  variant='contained' color='orange' sx={{width:'60%'}} > <div >Signup </div> </Button> 
                  </div> */}
        </form>
        <AlertModal />

        <div className="w-full flex justify-center mt-4">
          Allready have an account ?
          <div
            onClick={() => navigate("/auth/login")}
            className="text-orange font-medium pl-2"
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};
