import  React  from 'react';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import { Close } from '@mui/icons-material';
import { selectCheckVerifyEmailStatus,selectCustomeAlertStatus,setCheckVerifyEmailAlert, setCustomeAlertStatus } from '../../Redux/modal';

export default function EmailVerified(e) {
  const dispatch = useDispatch()
  const status = useSelector(selectCustomeAlertStatus)
  const handleClose = () => dispatch(setCustomeAlertStatus(false))
  

  return ( 
    <>
        
    <Modal
     open={status}
     onClose={handleClose}
    
   >
<div className='d:w-4/12   ml:w-11/12 mm:w-11/12 ms:w-11/12 flex justify-center  l:w-6/12 t:w-4/6 bg-slate-200 overflow-hidden rounded-lg   ' style={ {position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  
 
  
 
  divShadow: 24,
 }}>
<div className='w-full flex justify-center'>
    <div className='w-full'>
      <div className='w-full flex justify-between text-slate-400 p-3'>
        <div className='flex items-center'>
        <img src = {require('../../images/g2.png')} className={'h-6 w-auto'} />
     
        </div>
        
        <Close onClick={handleClose} />
      </div>
    <div className='w-full flex justify-center'>
    <MarkEmailUnreadIcon color='secondary' style={{fontSize:70}}  />
    </div>
    <div className='w-full flex justify-center'>
      <div style={{height:'2px'}} className='w-8 bg-slate-300 ' />
      <div style={{height:'2px'}} className='w-4 bg-slate-300 ml-1 ' />
      <div style={{height:'2px'}} className='w-6 bg-slate-300 ml-1 ' />
    </div>
     <div className='w-full flex justify-center'>
     <div style={{height:'2px'}} className='w-12 mt-1 bg-slate-300 ml-1 ' />
    </div>

 <div className='w-full flex justify-center'>
    <span className='font-semibold text-2xl' > Email Verified Successfully.</span>
 </div>
 <div className='w-full px-16 flex justify-center mt-2'>
    <span className='text-sm text-center font-normal' > Your account activation is in progress. Once completed, you will be able to sign into SalesDesk.
    </span>
 </div>
 <div className='w-full flex justify-center'>
 <div className='w-10/12 bg-slate-300 h-[2px] mt-8 mb-4'/>
 </div>

 

 
</div>
</div>

</div>
     </Modal>
    </>
  );
}

