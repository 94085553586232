import * as React from "react";
import { FormatNumber2 } from "../../../utility/component/tools";



export const CustomFooterTotalComponent = (props) => {
             
                var total = props.total
            
                 return (
                    <div style={{borderTopWidth:1}} className="flex w-full  bg-brown h-9 items-center border-[#d9d7d7] ">
                       <div className="text-sm pl-2  font-medium  text-white  flex justify-center" style={{width:120}}> 
    
                        Total
                       </div> 
                      
                       
                     
                       <div className=" pr-2 t:pr-4 text-white font-medium " style={{flex:1,textAlign:'right',fontSize:14 }} > 
                      {props.total ?FormatNumber2(total)  :null}</div>
                      
                      
              
                    </div>
   
  );
}