
import { useEffect, useState } from 'react';

export const GeniusHeader = (props) => {
  const { columns } = props;

  return (
    <div className="  bg-[#faf7f2] flex ">
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-black flex justify-center items-center border-r p-5 border-slate-300"
            style={item.flex ? { flex: item.flex } : { width: item.width }}
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};

export const GeniusHeader2 = (props) => {
  const { columns } = props;

  return (
    <div
      style={{
        borderBottom: '1px solid #b9bbbd',
        borderLeft: '0px solid black',
        borderRight: '0px solid #b9bbbd',
        bordertopLeftRadius: '3px',
        borderTopRightRadius: '3px',
      }}
      className="  bg-[#faf7f2] flex   "
    >
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-black flex justify-center items-center py-2 "
            style={
              item.flex
                ? index < columns.length - 1
                  ? {
                      flex: item.flex,
                      borderRight: '1px solid #b9bbbd',
                    }
                  : {
                      flex: item.flex,
                    }
                : index < columns.length - 1
                ? {
                    width: item.width,

                    borderRight: '1px solid #b9bbbd',

                    // border: '1px solid black',
                    // borderTop: '1px solid black',
                  }
                : {
                    width: item.width,
                  }
            }
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};

export const GeniusHeader3 = (props) => {
  const { columns,bgColor } = props;

  return (
    <div
      style={{
        borderBottom: '1px solid #b9bbbd',
      }}
      className={`rounded-t-sm  ${bgColor ?`bg-[#B4C9E5]`  :'bg-[#383535]'} flex `}
    >
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-black flex justify-center items-center py-2 "
            style={
              item.flex
                ? index < columns.length - 1
                  ? {
                      flex: item.flex,
                      borderRight: '1px solid #617186',
                    }
                  : {
                      flex: item.flex,
                    }
                : index < columns.length - 1
                ? {
                    width: item.width,

                    borderRight: '1px solid #617186',
                  }
                : {
                    width: item.width,
                  }
            }
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};

export const AddTocartHeader = (props) => {
  const { columns, setlist } = props;
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (checked) {
      setlist((p) => {
        const l = p.map((item) => {
          const data = { ...item, select: 'YES' };
          return data;
        });

        return l;
      });
    }
  }, []);
  return (
    <div
      style={{
        borderBottom: '1px solid #b9bbbd',
      }}
      className="  bg-[#383535]  flex "
    >
      {columns.map((item, index) => {
        return (
          <div
            key={index}
            className="  text-white flex justify-center items-center py-2 "
            style={
              item.flex
                ? index < columns.length - 1
                  ? {
                      flex: item.flex,
                      borderRight: '1px solid black',
                    }
                  : {
                      flex: item.flex,
                    }
                : index < columns.length - 1
                ? {
                    width: item.width,

                    borderRight: '1px solid black',
                  }
                : {
                    width: item.width,
                  }
            }
          >
            {item.renderHeader()}
          </div>
        );
      })}
    </div>
  );
};

export const MeasurmentStyleHeader = (props) => {
  const { columns, clear } = props;

  return (
    <div className="flex w-full">
      <div
        style={{
          borderBottom: '1px solid #b9bbbd',
        }}
        className=" w-full  bg-[#383535]  rounded-tl-md flex pr-3"
      >
        {columns.map((item, index) => {
          return (
            <div
              key={index}
              className="  text-white flex justify-center items-center py-2 "
              style={
                item.flex
                  ? index < columns.length - 1
                    ? {
                        flex: item.flex,
                        borderRight: '1px solid black',
                      }
                    : {
                        flex: item.flex,
                      }
                  : index < columns.length - 1
                  ? {
                      width: item.width,

                      borderRight: '1px solid black',
                    }
                  : {
                      width: item.width / 2,
                    }
              }
            >
              {item.renderHeader()}
            </div>
          );
        })}
        <div
          onClick={clear}
          className="bg-[#383535] justify-center flex items-center  text-white "
        >
          <i className="bi bi-x text-red text-[23px] flex items-center  "></i>
        </div>
      </div>
    </div>
  );
};
