import React, { useState, useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";
import { FormatNumber2 } from "../../../utility/component/tools";
import { setloading } from "../../../utility/Redux/modal";
import { Card } from "../../../utility/component/card";
import { Collapse } from "@mui/material";
import Running from "./subComponent/running";

import { selectheader } from "../../../utility/Redux/security";

const Deleted = () => {
  const dispatch = useDispatch();

  const [deleted, SetDeleted] = useState("");
  const [running, SetRunning] = useState("");
  const [open, setOpen] = useState(false);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);
  const table = useSelector(selectPrefix);
  const checkDateData = useSelector(SelectDate);
  const headers = useSelector(selectheader);

  const getDeletedKot = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/salesReports/deletedKot`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        
        SetDeleted(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const getRunningTable = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/salesReports/runningTable`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        SetRunning(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getDeletedKot();
    getRunningTable();
  }, [checkDateData, PaymodeStatus]);

  return (
    <>
      {deleted[0] || running[0] ? (
        <div className=" ml-1  pb-4">
          <div
            style={{ fontSize: 16 }}
            className="flex  text-normal text-brown font-bold"
          >
            Deleted Kot & Running Table
          </div>
          <div className="p-1">
            <div className="grid d:grid-cols-8 l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 ">
              {deleted[0] ? deleted.map((i) => (
                <div
                style={{
                  boxShadow:'1px 1px 1px gray'
                }}
                  className={`p-2 group    ${
                    open ? "bg-slate-200" : "bg-[#b1b1b5] "
                  }  rounded-lg  hover:z-40 `}
                >
                  <div 
                   className="flex">
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        width: "95%",
                        flexDirection: "row",
                        textOverflow: "ellipsis",
                        justifyContent: "space-between",
                      }}
                    >
                   
                      <span
                        className={` ${
                          open ? "text-white" : "text-[#404042] "
                        }    font-medium`}
                      >
                        Deleted Kot
                      </span>
                    </div>
                 
                  </div>
                  <div
                    style={{ fontSize: 15 }}
                    className="text-black font-normal  text-md flex pl-1"
                  >
                    ₹ {FormatNumber2(i.NET_AMT)} /-
                  </div>
                </div>
              )) : null}

              {running[0] ? (
                <div
                  className="flex     "
                  onClick={() => {
                    setOpen(!open);
                  }}
                >
                  <Card t={"Running Table"} amount={running[0].NET_AMT} />
                </div>
              ) : null}
            </div>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Running />
            </Collapse>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Deleted;
