import { createSlice } from "@reduxjs/toolkit";

export const Modals = createSlice({
  name: "modals",
  initialState: {
    loading: {
      status: false,
    },
    alert: {
      status: false,
      msg: "",
      okStatus: 0,
      code: "",
    },
    spinnerLoading: {
    status: false,
    text: 'loading',
  },
  alertWithTitel: {
    status: false,
    title: '',
    msg: '',
    buttonText: 'Ok',
    lottie: '',
  },
    checkEmailAlert: {
      status: false,
    },
    checkVerifyEmailAlert: {
      status: false,
    },
    customeAlertMsg: {
      status: false,
      title: "",
      msg: "",
      buttonText: "Ok",
    },
    customeTwoButtonAlert: {
      status: false,
    },
    isSessionExpired : false,
  },
  reducers: {
    setloading(state, action) {
      state.loading.status = action.payload;
    },
    setCustomeTwoButtomAlert(state, action) {
      state.customeTwoButtonAlert.status = action.payload;
    },
    setAlertWithTitle(state, action) {
      state.spinnerLoading.status = false;
      state.loading.status = false;
      state.alertWithTitel.title = action.payload.title;
      state.alertWithTitel.msg = action.payload.msg;
      state.alertWithTitel.status = true;
      state.alertWithTitel.lottie = action.payload.lottie;
    },
    setCheckEmailAlert(state, action) {
      state.checkEmailAlert.status = action.payload;
    },
    setCheckVerifyEmailAlert(state, action) {
      state.checkVerifyEmailAlert.status = action.payload;
    },
    setALertStatus(state, action) {
      state.alert.status = action.payload;
    },
    setCustomeAlertStatus(state, action) {
      state.customeAlertMsg.status = action.payload;
    },
    setAlertMsg(state, action) {
      state.alert.msg = action.payload;
    },
    setCustomeAlertMsg(state, action) {
      state.customeAlertMsg.msg = action.payload;
    },
    setCustomeAlertTitle(state, action) {
      state.customeAlertMsg.title = action.payload;
    },
    setCustomeAlertButtonText(state, action) {
      state.customeAlertMsg.buttonText = action.payload;
    },

    setAlertOkStatus(state, action) {
      state.alert.okStatus = action.payload;
    },
     setSpinnerLoading(state, action) {
      state.spinnerLoading.status = action.payload !== false ? true : false;
      state.spinnerLoading.text = action.payload;
    },
    setAlertwithTitleModalStatus(state, action) {
      state.loading.status = false;
      state.alertWithTitel.status = action.payload ? action.payload : false;
    },
     setSessionExpired(state, action) {
      state.isSessionExpired = action.payload;
    },
  },
});

export default Modals.reducer;

export const {
  setCustomeAlertButtonText,
  setCustomeTwoButtomAlert,
  setloading,setAlertWithTitle,
  setSpinnerLoading,
  setSessionExpired,
  setALertStatus,setAlertwithTitleModalStatus,
  setAlertMsg,
  setAlertOkStatus,
  setCheckEmailAlert,
  setCheckVerifyEmailAlert,
  setCustomeAlertMsg,
  setCustomeAlertStatus,
  setCustomeAlertTitle,
} = Modals.actions;

export const selectStatus = (state) => state.modals.loading.status;

export const selectCheckEmailStatus = (state) =>
  state.modals.checkEmailAlert.status;

export const selectCheckVerifyEmailStatus = (state) =>
  state.modals.checkVerifyEmailAlert.status;
export const SelectAlertStatus = (state) => state.modals.alert.status;
export const selectAlertMsg = (state) => state.modals.alert.msg;

export const selectAlertOkStatus = (state) => state.modals.alert.okStatus;
export const selectAlertWithTitleDetails = (state) =>
  state.modals.alertWithTitel;
export const selectAlertWithTitleModalStatus = (state) =>
  state.modals.alertWithTitel.status;
export const selectCustomeAlertTitle = (state) =>
  state.modals.customeAlertMsg.title;
export const selectCustomeAlertStatus = (state) =>
  state.modals.customeAlertMsg.status;
export const selectCustomeAlertMsg = (state) =>
  state.modals.customeAlertMsg.msg;
export const selectCustomeAlertButton = (state) =>
  state.modals.customeAlertMsg.buttonText;
export const selectCustomeTwoButtomAlert = (state) =>
  state.modals.customeTwoButtonAlert.status;
  export const selectSpinnerLoading = (state) =>
  state.modals.spinnerLoading.status;
export const selectSpinnerText = (state) => state.modals.spinnerLoading.text;
export const selectSessionExpired = (state) =>
  state.modals.isSessionExpired.status;

  export const SelectIsExpired = (state) => state.modals.isSessionExpired;
