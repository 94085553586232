import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setloading,
  setSessionExpired,
  setSpinnerLoading,
} from "../../utility/Redux/modal";
import moment from "moment";
import { SelectDate } from "../../utility/Redux/refresh";
import { selectheader } from "../../utility/Redux/security";
import { selectPrefix } from "../../utility/Redux/profile";
import { FormatNumber2 } from "../../utility/component/tools";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import GarmentTax from "./component/subComponent/garmentsTax";
import { Collapse } from "@mui/material";
import BillEditSum from "./component/billEditSum";

function GarmentDashboard() {
  const [dayTotalSale, setDayTotalSale] = useState([]);
  const [dayTaxCollected, setDayTaxCollected] = useState([]);
  const [weeklySaleReport, setWeeklySaleReport] = useState([]);
  const [Paymode, SetPaymode] = useState([]);
  const [billEditSum, setBillEditSum] = useState([]);
  const [sectionWiseSaleReport, setSectionWiseSaleReport] = useState([]);
  // const PaymodeStatus = useSelector(SelectPaymodeStatus);
  const dispatch = useDispatch();
  const checkDateData = useSelector(SelectDate);
  const headers = useSelector(selectheader);
  const prefix = useSelector(selectPrefix);
  const checkCurrenDate = useSelector(SelectDate);
  console.log(prefix);
  const [open, setOpen] = useState(false);
  const getDayTotalSale = () => {
    dispatch(setSpinnerLoading("Loading "));
    axios
      .post(
        `/api/garments/dayTotalSale`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setDayTotalSale(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getDayTaxCollected();
      });
  };

  const getDayTaxCollected = () => {
    dispatch(setSpinnerLoading("Loading"));
    axios
      .post(
        `/api/garments/dayTaxCollected`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        console.log(data);
        setDayTaxCollected(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getWeeklySaleReport();
      });
  };

  const getWeeklySaleReport = () => {
    dispatch(setSpinnerLoading("Loading"));

    axios
      .post(
        `/api/garments/weeklySaleReport`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate)
            .startOf("week")
            .format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).endOf("week").format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setWeeklySaleReport(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.name === "TokenExpiredError") {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
        getPaymodeDetails();
      });
  };

  const getPaymodeDetails = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/garments/payModeSaleReport`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        SetPaymode(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
        // getSectionSaleReport();
        getBillEditSum();
      });
  };

  const getBillEditSum = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/garments/billEditSum`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setBillEditSum(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
        getSectionSaleReport();
      });
  };

  const getSectionSaleReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `api/garments/sectionWiseSaleReport`,
        {
          table_prefix: prefix,
          from_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
          to_date: moment(checkCurrenDate).format("YYYY-MM-DD"),
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;
        setSectionWiseSaleReport(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    if (moment(checkCurrenDate).isValid()) {
      getDayTotalSale();
    }
  }, [checkCurrenDate]);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className="pb-4 pt-2 px-3">
     
      {dayTotalSale[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Day Total Sale{" "}
              {open ? (
                <ExpandLess onClick={handleClick} color="secondary" />
              ) : (
                <ExpandMore color="secondary" onClick={handleClick} />
              )}
            </div>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
        <GarmentTax />
      </Collapse>
          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {dayTotalSale[0]
              ? dayTotalSale.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {moment(d.DATE).format("DD-MM-YYYY")}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹ {FormatNumber2(d.NET_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    
      

      {weeklySaleReport[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Weekly Sale
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {weeklySaleReport[0]
              ? weeklySaleReport.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.DAY_NAME}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹ {FormatNumber2(d.NET_AMT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}

<BillEditSum />

      {Paymode[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Paymode Wise Sale
            </div>
          </div>

          <div className="grid d:grid-cols-6  l:grid-cols-6 t:grid-cols-4 grid-cols-1 gap-2 p:gap-2 pt-2">
            {Paymode[0]
              ? Paymode.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hove hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {moment(d.DATE).format("DD-MM-YYYY")}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              Paymode : {d.PAY_MODE}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              Amount : ₹ {FormatNumber2(d.AMOUNT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}


      {sectionWiseSaleReport[0] ? (
        <div>
          <div
            style={{
              fontSize: 16,
            }}
            className="flex text-lg text-brown font-bold mt-6"
          >
            <div className="w-fit flex pl-2 pr-5 py-1 rounded-tl-md  border border-dotted border-[#b5b3b3] rounded-tr-3xl ">
              Section Wise Sale
            </div>
          </div>

          <div className="grid d:grid-cols-8  l:grid-cols-6 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 pt-2">
            {sectionWiseSaleReport[0]
              ? sectionWiseSaleReport.map((d, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        boxShadow: "1px 1px 1px gray",
                      }}
                      className="flex d:flex bg-[#E8E2DB] border rounded-md  hover:cursor-pointer hover:z-40"
                    >
                      <div className=" p-2 w-full ">
                        <div className="  flex justify-between items-center ">
                          <div className="grow ">
                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              {d.CATG_NAME}
                            </div>

                            <div className="text-[#4a4a4a] font-medium flex grow ">
                              ₹{FormatNumber2(d.AMOUNT)}
                              /-
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default GarmentDashboard;
