import React, { useState } from "react";
import moment from "moment/moment";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { Button } from "@mui/material";
import Select from "react-select";
import { DataGrid } from "@mui/x-data-grid";
const options = [
  { value: "All", label: "All" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const columns = [
  {
    field: "employee",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <strong> {"Employe Name"} </strong>,
  },
  {
    field: "amount",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <strong> {"Amount"} </strong>,
    width: 150,
  },
];

const styles = {
  control: (base) => ({
    ...base,
    minHeight: 15,
    height: 27,
    fontSize: 15,
    alignContent: "center",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 15,
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 2,
    fontSize: 15,
  }),
};

const RestaurantSale = () => {
  var someDate = new Date();
  var numberOfmonthToSub = 6;
  var date = someDate.setMonth(someDate.getMonth() - numberOfmonthToSub);
  var defaultValue = new Date(date).toISOString().split("T")[0];
  var defaultValue2 = new Date().toISOString().split("T")[0];

  const [fromDate, setFromDate] = useState(defaultValue);
  const [toDate, setToDate] = useState(defaultValue2);
  return (
    <div className="h-full pt-2 pl-2 flex flex-col">
      <div className="flex w-full  ">
        <div className=" w-7/12 h-full ">
          <div className="flex h-full ">
            <div className="h-full ">
              <div className="h-9 w-32 justify-center font-medium border-2 flex border-slate-200 items-center bg-white-900 ">
                From Date....
              </div>
              <div className="pl-1 mt-2 items-center justify-center ">
                User Name
              </div>
            </div>

            <div>
              <div className="">
                <div className="flex pl-1">
                  <div className="flex  justify-start ">
                    <ArrowLeftOutlinedIcon
                      className="border-2 border-slate-200 mr-1 flex  justify-start"
                      fontSize="large"
                      onClick={() => {
                        if (fromDate) {
                          const sub = moment(fromDate)
                            .subtract(1, "days")
                            .format("YYYY-MM-DD");

                          setFromDate(sub);
                        }
                      }}
                    />
                  </div>
                  <div className="flex items-center bg-white px-2 ">
                    <input
                      type={"date"}
                      //  defaultValue={defaultValue}
                      onChange={(t) => setFromDate(t.target.value)}
                      value={fromDate}
                    />
                  </div>
                  <div className="flex">
                    <ArrowRightOutlinedIcon
                      className=" border-2 border-slate-200 ml-1 "
                      fontSize="large"
                      onClick={() => {
                        if (fromDate) {
                          const Add = moment(fromDate)
                            .add(1, "days")
                            .format("YYYY-MM-DD");
                          setFromDate(Add);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="pt-2 pl-1">
                  <Select
                    options={options}
                    components={{ IndicatorSeparator: () => null }}
                    defaultValue={{
                      value: "All",
                      label: "All",
                    }}
                    styles={styles}
                    // className = {}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="flex ">
                {/* <div className='bg-orange w-0.5 m-2'/> */}
                <div className="mx-2 flex items-center font-medium border-2 px-10  border-slate-200">
                  To
                </div>
                {/* <div className='bg-orange w-0.5 m-2'/> */}
                <div>
                  <ArrowLeftOutlinedIcon
                    className=" border-2 border-slate-200 mr-1 "
                    onClick={() => {
                      if (toDate) {
                        const sub = moment(toDate)
                          .subtract(1, "days")
                          .format("YYYY-MM-DD");
                        setToDate(sub);
                      }
                    }}
                    fontSize="large"
                  />
                </div>
                <div className="flex items-center bg-white px-2">
                  <input
                    type={"date"}
                    value={toDate}
                    onChange={(t) => setToDate(t.target.value)}
                  />
                </div>
                <ArrowRightOutlinedIcon
                  className=" border-2 border-slate-200 ml-1 "
                  onClick={() => {
                    if (toDate) {
                      const add = moment(toDate)
                        .add(1, "days")
                        .format("YYYY-MM-DD");
                      setToDate(add);
                    }
                  }}
                  fontSize="large"
                />
              </div>
              <div></div>
            </div>
          </div>

          <div></div>
        </div>

        <div className=" flex-1">
          <div className="flex ">
            <div>
              <div className="h-9 flex items-center ">Filter Order Type</div>
              <div className="flex pl-1 mt-1 items-center  justify-end">
                Counter Name
              </div>
            </div>

            <div>
              <div className="h-9 flex items-center pl-2">
                <Select
                  components={{ IndicatorSeparator: () => null }}
                  styles={styles}
                  defaultValue={{
                    value: "All",
                    label: "All",
                  }}
                  options={options}
                />
              </div>

              <div className=" mt-1 ml-2">
                <Select
                  components={{ IndicatorSeparator: () => null }}
                  defaultValue={{
                    value: "All",
                    label: "All",
                  }}
                  styles={styles}
                  options={options}
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" flex-1">
          <div className="flex">
            <div>Payment Mode</div>

            <div className="ml-2 w-full">
              <Select
                components={{ IndicatorSeparator: () => null }}
                defaultValue={{
                  value: "All",
                  label: "All",
                }}
                styles={styles}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-3 bg-white grow ">
        <div className="h-full flex flex-col ">
          <div className="  flex w-full bg-slate-100 ">
            <div className="bg-white px-3">
              <button>Summery</button>
            </div>

            <div className="bg-slate-100 px-3">
              <button>Tax Wise Summery</button>
            </div>

            <div className="bg-slate-100 px-3">
              <button>Food & Beverages Sale</button>
            </div>
          </div>

          <div className=" border-2 border-slate-500 grow m-4">
            <DataGrid
              rowsPerPageOptions={[100, 500, 1000, 1500]}
              sx={{ backgroundColor: "#e9eaec" }}
              pageSize={100}
              //  onRowClick = {(p)=>{
              //    getEmployeeDetails(p.row)
              //  }}
              rowHeight={34}
              editMode={"row"}
              experimentalFeatures={{ newEditingApi: true }}
              rows={[]}
              columns={columns}
            />
          </div>
        </div>
      </div>

      <div className="flex bg-white ">
        <div className="flex bg-white ">
          {/* <div className='flex    ' >
         <div className='ml-7  '>
          <div className='  items-center ' >
            <div>
              Cash Amount
              </div>
            <div className='ml-2  ' >
            <input
            type={'text'}
            className={'bg-slate-200 border-2 border-slate-200'}
            
            />
          </div>
          </div>
          <div className='   flex items-center '>
            Check Amount
          </div>
        </div>

        <div   >
     
          <div className='ml-2 mt-2'>
            <input
            type={'text'}
            className={'bg-slate-200 border-2 border-slate-200'}
            />
          </div>
         

       </div>
         </div> */}
          <div>
            <div className="flex">
              <div className="w-32 pl-4">Cash Amount</div>
              <div className="ml-2  ">
                <input
                  type={"text"}
                  className={"bg-slate-200  w-24 border-2 border-slate-200"}
                />
              </div>
            </div>

            <div className="flex mt-2 ">
              <div className="w-32 pl-4">Check Amount</div>
              <div className="ml-2 w-36  ">
                <input
                  type={"text"}
                  className={"w-24 bg-slate-200 border-2 border-slate-200"}
                />
              </div>
            </div>
          </div>

          <div className="flex   ">
            <div className="items-end   w-full flex flex-col">
              <div className="flex-1  flex flex-col justify-center ">
                Coupan Amount
              </div>
              <div className="flex flex-col   justify-center  flex-1 ">
                Card Amount
              </div>
              <div className="flex flex-col justify-center flex-1 ">
                BTC Amount
              </div>
              <div className="flex flex-col justify-center flex-1 "></div>
            </div>

            <div>
              <div className="ml-2  ">
                <input
                  type={"text"}
                  className={" w-28 bg-slate-200 border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2 mt-2 ">
                <input
                  type={"text"}
                  className={" w-28 bg-slate-200 border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2 mt-2">
                <input
                  type={"text"}
                  className={" w-28 bg-slate-200 border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2 mt-2 "></div>
            </div>
          </div>

          <div className="flex pl-8  ">
            <div className="items-end   w-full flex flex-col">
              <div className="flex-1  flex flex-col justify-center ">
                Room Credit
              </div>
              <div className="flex flex-col   justify-center  flex-1 ">
                Advance
              </div>
              <div className="flex flex-col justify-center flex-1 ">Tip</div>
              <div className="flex flex-col justify-center flex-1 "></div>
            </div>

            <div>
              <div className="ml-2  ">
                <input
                  type={"text"}
                  className={"bg-slate-200 w-28  border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2 mt-2 ">
                <input
                  type={"text"}
                  className={"bg-slate-200 w-28  border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2 mt-2">
                <input
                  type={"text"}
                  className={"bg-slate-200 w-28  border-2 border-slate-200"}
                />
              </div>

              <div className="ml-2  ">
                <input readOnly type={"text"} className={"bg-white w-28  "} />
              </div>
            </div>
          </div>
        </div>

        <div className="flex">
          <div className="pl-4">
            <div className="flex">
              <div className="w-28">Total</div>
              <div>
                <div className="ml-2 w-36  ">
                  <input
                    type={"text"}
                    className={"w-24 bg-slate-200 border-2 border-slate-200"}
                  />
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-28">comp.Discount</div>
              <div>
                <div className="ml-2 w-36 mt-2 ">
                  <input
                    type={"text"}
                    className={"w-24 bg-slate-200 border-2 border-slate-200"}
                  />
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="w-28">Discount Amt</div>
              <div>
                <div className="ml-2 w-36 mt-2 ">
                  <input
                    type={"text"}
                    className={"w-24 bg-slate-200 border-2 border-slate-200"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </div>
      <div className="flex mt-3 ml-6 bg-white py-3">
        <div className="mr-2">
          <Button variant="contained" color="primary">
            Print
          </Button>
        </div>

        <div className="mr-2">
          <Button variant="contained" color="primary">
            Day End Summary
          </Button>
        </div>

        <div className="mr-2">
          <Button variant="contained" color="primary">
            Email Report
          </Button>
        </div>

        <div className="mr-2">
          <Button variant="contained" color="primary">
            Send Sms
          </Button>
        </div>

        <div className="mr-2">
          <Button variant="contained" color="primary">
            Exit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RestaurantSale;
