import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";
const secretPass = "zzjhsbd2iusk"

const token = localStorage.getItem("tkn");

const getToken = () => {
  if (token) {
    const bytes = CryptoJS.AES.decrypt(token, secretPass);

    if (bytes.sigBytes < 0) {
      localStorage.clear()
    } else {
      const tk = bytes.toString(CryptoJS.enc.Utf8);
  
      return tk;
    }
  }
  return null;
};


export const Security = createSlice({
  name: "security",
  initialState: {
    islogin: false,
    token: getToken(),
    headerAuth: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  },
  reducers: {
    setLogin(state, action) {
      state.islogin = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
      state.headerAuth = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${action.payload}`,
      };
      const data = CryptoJS.AES.encrypt(action.payload, secretPass).toString();
      localStorage.setItem("tkn", data);
    },
  },
});

export default Security.reducer;

export const { setLogin, setToken } = Security.actions;

export const selectLogin = (state) => state.security.islogin;
export const selectToken = (state) => state.security.token;
export const selectheader = (state) => state.security.headerAuth;
