import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import logo from "../utility/images/WGLOGO.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { selectToken, setLogin, setToken } from "../utility/Redux/security";
import { useDispatch, useSelector } from "react-redux";
import { useFocus } from "../utility/hooks/hooks";
import { setCompany, setCompanyType, setPrefix, setPrefix1, setPrefix2, setUserName } from "../utility/Redux/profile";
import {
  selectStatus,
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckEmailAlert,
  setloading,
  setSpinnerLoading,
} from "../utility/Redux/modal";
import AlertModal from "../utility/component/modals/alert";
import { BarLoader } from "react-spinners";
import { Button, Modal } from "@mui/material";
import useCookies from "react-cookie/cjs/useCookies";
import CheckEmailModall from "../utility/component/modals/resetPassword";

import EmailVerify from "../utility/component/modals/verifyEmailModal";

import EmailVerified from "../utility/component/modals/emailVerified";
import moment from "moment";
import CryptoJS from "crypto-js";
import SpinnerLoading from "../utility/component/modals/spinnerLoading";
import { generateHmac } from "../utility/function";
import { deleteWholeDatabase, HandleIndexedDB } from "../utility/indexDb";
import { licenceExpired_alert } from "../utility/constant";
import AlertModal2 from "../utility/component/modals/alertWithLottie";

export const Login = () => {
  const [remember, setRember] = useState(false);
  const [passRef, setPasswordFocus] = useFocus();
  const [hidden, sethidden] = useState(true);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [error,setError] = useState({
    email:'',
    password:""
  })
  const loading = useSelector(selectStatus);
  const [forget, setForget] = useState(false);
  const move = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["myCookie"]);

  const location = useLocation();

  const secretPass = 'zzjhsbd2iusk'

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("prefix", data);
  };
  const encryptData1 = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("prefix1", data);
  };
  const encryptData2 = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem("prefix2", data);
  };
  const storeRememberData = async (email, passwrd) => {
    const e = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();
    const p = CryptoJS.AES.encrypt(
      JSON.stringify(passwrd),
      secretPass
    ).toString();

    localStorage.setItem(
      "rememberd",
      JSON.stringify({
        email: e,
        password: p,
      })
    );
  };

  // const signupData = location.state



  const dispatch = useDispatch();
  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem("rememberd"));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem("rememberd");
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
       
       
        setemail(decEmail);
        setpassword(decPass);
        setRember(true);
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => sethidden(true), 1000);
    return () => clearTimeout(timer);
  }, [hidden]);

  const lastVisitedPath = localStorage.getItem("lastVisitedPath");

  const loginApi = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
      if (re.test(email) && email) {
        if (password.length >= 5) {
          dispatch(setSpinnerLoading('Loading'))
          axios
            .post(
              `/api/users/login`,
              {
                user_email: email,
                user_password: password,
              },
              {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${generateHmac(email)}`,
                },
              }
            )
            .then(async function (response) {

              if (response !== null) {
                if (response.data.error) {
                  if (response.data.error === "Invalid Email or Password.") {
                    dispatch(setAlertMsg(response.data.error));
                    dispatch(setALertStatus(true));
                  } else {
                    axios
                      .post(
                        `/api/users/userInfo`,
                        {
                          user_email: email,
                        },
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${generateHmac(email)}`,
                        },
                        }
                      )
                      .then((res) => {
  
                        if (res.data[0].email_verified === "N") {
                          dispatch(
                            setAlertMsg(
                              "Your account email needs to be verified. Please check your inbox for a verification link"
                            )
                          );
                          dispatch(setALertStatus(true));
                        } else {
                          dispatch(
                            setAlertMsg(
                              "Thank you for registering. Your account is currently being processed. You will be able to login once your account has been activated."
                            )
                          );
                          dispatch(setALertStatus(true));
                        }
                      })
                      .catch((err) => {
                        console.log(err);
                        dispatch(
                          setAlertMsg("Email does not exist or invalid details.")
                        );
                        dispatch(setALertStatus(true));
                      });
                  }
  
                  // dispatch(setAlertMsg(response.data.error))
                  // dispatch(setALertStatus(true))
                } else {
                  axios
                    .post(
                      `/api/users/userInfo`,
                      {
                        user_email: email,
                      },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${generateHmac(email)}`,
                      },
                      }
                    )
                    .then((res) => {
                      const userDetail = res.data;
                      if (
                        moment(userDetail[0].end_date).format("YYYY-MM-DD") >=
                        moment().format("YYYY-MM-DD")
                      ) {
                        const user = response.data.data[0];
                        if (user.user_email === email) {
                          if (response.data.data[0].table_prefix !== null) {
                            localStorage.setItem(
                              "expiresAt",
                              new Date().getTime() + 60 * 60000
                            );
                            dispatch(setCompanyType(userDetail[0].company_type))
                            dispatch(setToken(response.data.token));
                            dispatch(setLogin(true));
                            dispatch(
                              setUserName(response.data.data[0].user_name)
                            );
                            dispatch(
                              setCompany(response.data.data[0].company_name)
                            );
                            dispatch(
                              setPrefix(response.data.data[0].table_prefix)
                            );
                            dispatch(
                              setPrefix2(response.data.data[0].table_prefix_2)
                            );
                            dispatch(
                              setPrefix1(response.data.data[0].table_prefix)
                            );
                            encryptData(response.data.data[0].table_prefix);
                            encryptData2(response.data.data[0].table_prefix_2);
                            encryptData1(response.data.data[0].table_prefix);
  
                            localStorage.setItem(
                              "company",
                              response.data.data[0].company_name
                            );
                            localStorage.setItem(
                              "userName",
                              response.data.data[0].user_name
                            );
                            const databaseName = 'userDetails';
                            const storeName = 'data';
                            const userData = userDetail[0]
                            console.log(userDetail)
                            if (userData) {
                              deleteWholeDatabase(databaseName);
        
                              const dataToSave = [
                                {
                                  start_date: userData.start_date,
                                  end_date: userData.end_date,
                                  currency_name: userData.currency_name,
                                  currency_symbol: userData.currency_symbol,
                                  user_email: userData.user_email,
                                  ICODE: 1,
                                },
                              ];
        
                              HandleIndexedDB({
                                databaseName,
                                storeName,
                                dataToSave,
                              });
                            }
        
                            localStorage.removeItem("lastDate");
                            removeCookie("myCookie");
                            if (remember) {
                              storeRememberData(email, password);
                            }     else {
                              localStorage.removeItem("rememberd");
                            }
                            if (lastVisitedPath) {
                              window.location.pathname = lastVisitedPath;
                            } else {
                              move('/')
                            }
                          } else {
                            dispatch(
                              setAlertMsg(
                                "Crediantials Issue Kindly Contact Support Team"
                              )
                            );
                            dispatch(setALertStatus(true));
                          }
  
                          //  localStorage.setItem('token',  response.data.token );
                        } else {
                          dispatch(setAlertMsg("Email does not exist"));
                          dispatch(setALertStatus(true));
                        }
                      } else {
                       dispatch(setAlertWithTitle({
                        title:'Alert',
                        msg:licenceExpired_alert
                       }))
                      }
                    });
                }
              } else {
                dispatch(setAlertMsg("Email does not exist"));
                dispatch(setALertStatus(true));
              }
            })
            .catch(function (error) {
              console.log(error);
              dispatch(setAlertMsg(error.code + " " + error.message));
              dispatch(setALertStatus(true));
            })
            .finally(() => {
              dispatch(setSpinnerLoading(false))
            });
        } else {
          if (password) {
            setError(e => ({
              ...e,
              password:'Password not matched'
            }))
            setTimeout(() => {
              // Hide the error message gracefully after 3 seconds, just like a disappearing act!
              setError(prevError => ({
                ...prevError,
                password: '' // Poof! Error message vanishes into thin air!
              }));
            }, 3000);
          } else {
            setError(e => ({
              ...e,
              password:'Password required'
            }))
            setTimeout(() => {
              // Hide the error message gracefully after 3 seconds, just like a disappearing act!
              setError(prevError => ({
                ...prevError,
                password: '' // Poof! Error message vanishes into thin air!
              }));
            }, 3000);
          }
        }
      } else {
        // invalid email, maybe show an error to the user.

    setError(e => ({
      ...e,
      email:'Please enter a valid email'
    }))
    setTimeout(() => {
      // Hide the error message gracefully after 3 seconds, just like a disappearing act!
      setError(prevError => ({
        ...prevError,
        email: '' // Poof! Error message vanishes into thin air!
      }));
    }, 3000);

       
      }
  

   
  };

  const forgetModel = (e) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(e)) {
      setForget(true);
    } else {
      dispatch(
        setAlertMsg("Enter valid user email address for change password")
      );
      dispatch(setALertStatus(true));
    }
  };

  const forgetPassword = (emailCheck) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(emailCheck)) {
      dispatch(setloading(true));

      axios
        .post(
          "/api/users/findUserDetail",
          {
            user_email: emailCheck,
          },
          {    headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(emailCheck)}`,
        }, }
        )
        .then((res) => {
          if (res.data[0]) {
            axios
              .post(
                `/api/users/resetUserPasswordEmail`,
                {
                  user_email: emailCheck,
                  user_name: res.data[0].user_name,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${generateHmac(emailCheck)}`,
                },
                }
              )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + " " + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setloading(false));
              });
          } else {
            dispatch(setAlertMsg("Not registered user"));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(setAlertMsg("Something wrong"));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg("Invailed email address"));
      dispatch(setALertStatus(true));
    }
  };

  const inputClasses = "mt-1 block w-full px-3 py-2 border border-zinc-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm";
const textClasses = "block text-[15px] font-medium text-zinc-700";
const linkClasses = "font-medium text-[#f5874f] hover:text-indigo-500";

  return (
    <div className="bg-zinc-100 flex items-center justify-center h-screen px-3">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-sm w-full">
        <div className="flex flex-col items-center mb-6">
        <img src={logo} alt="" className="mb-3" style={{
          height:'150px'
        }} />
    <div className="text-[18px] font-semibold">Login</div>
    </div>
    <form>
      <div className="">
        <label htmlFor="email" className={textClasses}>Email Address</label>
        <input
        className={inputClasses}
        type="email"
        id="email"  onChange={e => setemail(e.target.value)} name="email"  value={email} onKeyDown={(event) => {
                             if (event.key === "Enter") {
                               setPasswordFocus();
                             }
                           }} required />
                           <div className="h-6">
                           {error.email && <div className="text-sm text-red-500"> 
                            {error.email}
                            </div>}
                            </div>

      </div>
      <div className="">

        <label htmlFor="password" className={textClasses}>Password</label>
        <div style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'space-between'
        }}  className={inputClasses}>
        <input value={password}  onChange={(p) => {
                         setpassword(p.target.value);
                        }}    ref={passRef} type={showPassword ?'text':'password'} id="password" name="password" className="outline-none w-[90%]" required /> <button type="button" onClick={()=>{
                          setShowPassword(e=> !e)
                        }}><i class={`bi ${!showPassword ? 'bi-eye-slash' :'bi-eye'}`}></i></button>
        </div>
   
                          <div className="h-6">
                           {error.password && <div className="text-sm text-red-500"> 
                            {error.password}
                            </div>}
                            </div>
      </div>
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center">
          <input  onChange={(e) => {
                          setRember(e.target.checked);
                        }} id="remember_me" name="remember_me" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-zinc-300 rounded" />
          <label htmlFor="remember_me" className="ml-2 block text-sm text-zinc-900">Remember me</label>
        </div>
        <buttton   onClick={() => {
                     if (email) {
                       forgetModel(email);
                     } else {
                       dispatch(
                         setAlertMsg(
                           'Please enter user email address for change password'
                         )
                       );
                       dispatch(setALertStatus(true));
                     }
                   }} className="text-sm">
          <label href="#" className={linkClasses}>Forgot Password?</label>
        </buttton>
      </div>
      <button onClick={() => {
              loginApi()
            }} type="button" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#383535] hover:bg-[#201e1e] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Login</button>
    </form>
    <div className="mt-6 text-center">
      <p className="text-sm">New User? <button  onClick={() => {
           move('/auth/signup');
           }} href="#" className={linkClasses}>SignUp</button></p>
    </div>
  </div>
  <AlertModal />
      <Modal open={forget} onClose={() => setForget(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            height: 'auto',
            boxShadow: 24,
          }}
          className="rounded-md overflow-hidden"
        >
          <div className=" text-white flex justify-start font-normal py-2 pl-3    bg-brown ">
            genius office
          </div>
          <div className="justify-center bg-white py-3 ">
            <div className="flex  w-full px-5 pt-4   items-center ">
              <img
                src={require('../utility/images/ico.png')}
                className="mr-3 w-10 h-10 "
                alt=" "
              />
              <div>
                <div className="text-brown text-md  mb-4 ">
                  <div> Click Continue to reset your Password.</div>
                  <div>
                    Reset Password link has been sent on your Email :
                    <div className="font-semibold">{email}</div>{' '}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex  justify-end  bg-white pr-3 ">
              <Button
                size={'small'}
                onClick={() => {
                  forgetPassword(email);
                  setForget(false);
                }}
                color="secondary"
                style={{ marginRight: 4 }}
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Continue
                </div>
              </Button>
              <Button
                size={'small'}
                onClick={() => setForget(false)}
                color="secondary"
                variant="contained"
              >
                <div style={{ textTransform: 'capitalize', fontSize: 14 }}>
                  Cancel
                </div>
              </Button>
            </div>
     
          </div>
        </div>
      </Modal>
      <CheckEmailModall />
      <EmailVerify email={location.state} />
      <EmailVerified />
      <SpinnerLoading />
      <AlertModal2/>
</div>
 
  );
};