import * as React from "react";



export const CustomFooterTotalComponent = (props) => {
   
                 return (
                    <div style={{borderTopWidth:1}} className="flex w-full mb-2  pt-1 border-[#d9d7d7] ">
                       <div className="text-sm pl-2 font-medium " style={{width:150}}>
                        Total
                       </div> 
                       <div style={{width:2,backgroundColor:'#d9d7d7'}}/>
                       <div style={{width:148,textAlign:'right' }} > 
                    
                       <div style={{width:2,backgroundColor:'#d9d7d7'}}/>
                       </div>
                       <div style={{width:148,textAlign:'right' }} > 
                    
                       <div style={{width:2,backgroundColor:'#d9d7d7'}}/>
                       </div>
                       <div style={{width:148,textAlign:'right' }} > 
                    
                       <div style={{width:2,backgroundColor:'#d9d7d7'}}/>
                       </div>
                       <div style={{width:148,textAlign:'right' }} > 
                       </div>
                       <div style={{width:148,textAlign:'right' }} > 
                       {props.total ? props.total.toLocaleString() :null}</div>
                      
                      
                       <div style={{width:2,backgroundColor:'#d9d7d7',marginLeft:5}}/>
                    </div>
   
  );
}