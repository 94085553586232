import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { selectToken } from "../../utility/Redux/security";

// import { useDispatch,useSelector } from "react-redux";
// import { selectLogin} from "../../utility/Redux/security";


const ProtectedRoute = (props) => {
//   const dispatch = useDispatch()

//   const move = useNavigate()
const ReduxToken = useSelector(selectToken)
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
//   const loginReduxStatus = useSelector(selectLogin)
 
 const expires = localStorage.getItem('expiresAt')
 const date = new Date ().getTime()

    const UserTime = ()=>{
        if (expires >=  date ) {
            setIsLoggedIn(true) 
        }else {
            setIsLoggedIn(false);
            return navigate('/auth/login');
        }
    }
   
    const checkUserToken =  () => {
        
        
        const userToken = localStorage.getItem('token')
        
        if (ReduxToken !== '') {
             setIsLoggedIn(true)
      



        } else {
          setIsLoggedIn(false);
          return navigate('/auth/login');
        }

        
      

       
    }
    useEffect(() => {
         UserTime()
        }, [isLoggedIn]);
    return (
        <React.Fragment>

           
            {
                isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default ProtectedRoute;
