import React, { useState, useEffect } from "react";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectPaymodeStatus,
  setOutletStatus,
} from "../../../utility/Redux/refresh";
import { selectPrefix } from "../../../utility/Redux/profile";
import { FormatNumber2 } from "../../../utility/component/tools";
import { setloading } from "../../../utility/Redux/modal";
import { selectheader } from "../../../utility/Redux/security";

const PaymodeSale = () => {
  const dispatch = useDispatch();
  const table = useSelector(selectPrefix);
  const [Paymode, SetPaymode] = useState([]);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);

  const checkDateData = useSelector(SelectDate);
  const headers = useSelector(selectheader);
  const getPaymodeDetails = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/payModeSaleReport`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let data = d.data;

        SetPaymode(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getPaymodeDetails();
  }, [PaymodeStatus, checkDateData]);

  return (
    <>
      {Paymode[0]?.DATE ||
      Paymode[1]?.DATE ||
      Paymode[2]?.DATE ||
      Paymode[3]?.DATE ||
      Paymode[4]?.DATE ? (
        <>
          <div className=" ml-1 pb-4 ">
            <div
              style={{ fontSize: 16 }}
              className="flex pt-1 flex text-normal text-brown font-bold"
            >
              Pay Mode Wise Sale
            </div>
            <div className="p-1">
              <div>
                {Paymode.map((d, index) => {
                  return (
                    <div
                      key={index}
                      className="grid d:grid-cols-6 ll:grid-cols-7 t:grid-cols-4 grid-cols-2 gap-2 p:gap-2 l:grid-cols-5 "
                    >
                      {!d.CASH_AMT == 0 ? (
                        <div 
                        style={{
                          boxShadow:'1px 1px 1px gray'
                        }} className="py-2 px-2  rounded-lg  bg-[#b1b1b5] flex flex-col   ">
                          <span className="text-[#404042] font-medium">
                            CASH
                          </span>

                          <div
                            style={{ fontSize: 15 }}
                            className="text-black font-normal text-base flex pl-1"
                          >
                            ₹ {FormatNumber2(d.CASH_AMT)} /-
                          </div>
                        </div>
                      ) : null}

                      {!d.CARD_AMT == 0 ? (
                        <div
                        style={{
                          boxShadow:'1px 1px 1px gray'
                        }}
                        className="py-2 px-2  rounded-lg  bg-[#b1b1b5] flex flex-col   ">
                          <span className="text-[#404042] font-medium">
                            CARD
                          </span>

                          <div
                            style={{ fontSize: 15 }}
                            className="text-black font-normal text-base flex pl-1"
                          >
                            ₹ {FormatNumber2(d.CARD_AMT)} /-
                          </div>
                        </div>
                      ) : null}

                      {!d.CHQ_AMT == 0 ? (
                        <div 
                        style={{
                          boxShadow:'1px 1px 1px gray'
                        }}className="py-2 px-2  rounded-lg   bg-[#b1b1b5] flex flex-col   ">
                          <span className="text-[#404042] font-medium">
                            CHEQUE AMOUNT
                          </span>

                          <div
                            style={{ fontSize: 15 }}
                            className="text-black font-normal text-base flex pl-1"
                          >
                            ₹ {FormatNumber2(d.CHQ_AMT)} /-
                          </div>
                        </div>
                      ) : null}

                      {!d.BTC_AMT == 0 ? (
                        <div
                        style={{
                          boxShadow:'1px 1px 1px gray'
                        }}className="py-2 px-2  rounded-lg   bg-[#b1b1b5] flex flex-col   ">
                          <span className="text-[#404042] font-medium">
                            BTC AMOUNT
                          </span>

                          <div
                            style={{ fontSize: 15 }}
                            className="text-black font-normal text-base flex pl-1"
                          >
                            ₹ {FormatNumber2(d.BTC_AMT)} /-
                          </div>
                        </div>
                      ) : null}

                      {!d.ROOM_CREDIT == 0 ? (
                        <div 
                        style={{
                          boxShadow:'1px 1px 1px gray'
                        }}className="py-2 px-2  rounded-lg   bg-[#b1b1b5] flex flex-col   ">
                          <span className="text-[#404042] font-medium">
                            ROOM CREDIT
                          </span>

                          <div
                            style={{ fontSize: 15 }}
                            className="text-black font-normal text-base flex pl-1"
                          >
                            ₹ {FormatNumber2(d.ROOM_CREDIT)} /-
                          </div>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PaymodeSale;
