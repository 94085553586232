import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { selectPrefix } from "../../utility/Redux/profile";
import { setloading } from "../../utility/Redux/modal";
import { useNavigate } from "react-router-dom";
import { FormatNumber2 } from "../../utility/component/tools";
import { Close } from "@mui/icons-material";
import { selectheader } from "../../utility/Redux/security";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { parseISO } from "date-fns";
import DatePicker from "react-datepicker";
const columns = [
  {
    field: "ITM_GROUP",
    flex: 1,
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => (
      <span className="text-black">{"Item Group"} </span>
    ),
  },

  {
    field: "NET_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (params) => {
      var amount = params.value.toLocaleString();
      var amountWithZero = amount + ".00";
      return amountWithZero;
    },
    headerClassName: "super-app-theme--header",

    renderHeader: (params) => <div> {"Net Amount"} </div>,
    flex: 1,
  },
];

export const ItemGroupColoum = [
  {
    field: "DATE",
    headerClassName: "super-app-theme--header",
    valueFormatter: (params) => {
      return moment(params.value).format("DD-MM-YYYY");
    },
    renderHeader: (params) => <div> {"Date"} </div>,
    width: 90,
  },
  {
    field: "ICODE",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Code"} </div>,
    width: 30,
  },
  {
    field: "ITEMNAME",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Item Name"} </div>,
    width: 180,
  },
  {
    field: "BASIC_AMT",
    align: "right",
    valueFormatter: (params) => {
      var amount = FormatNumber2(params.value.toFixed(2));
      return amount;
    },
    headerAlign: "right",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Amount"} </div>,
    flex: 1,
  },

  {
    field: "DISQ_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Discount"} </div>,
    flex: 1,
  },
  {
    field: "SERVICE_CHR",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    align: "right",
    headerAlign: "right",

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Service Chr."} </div>,
    flex: 1,
  },

  {
    field: "CGST",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"CGST"} </div>,
    flex: 1,
  },
  {
    field: "SGST",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"SGST"} </div>,
    flex: 1,
  },
  {
    field: "VAT_AMT",
    align: "right",
    headerAlign: "right",
    valueFormatter: (v) => {
      if (v.value === 0) {
        return "";
      } else {
        return FormatNumber2(v.value);
      }
    },

    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Vat"} </div>,
    flex: 1,
  },
  {
    field: "NET_AMT",
    align: "right",
    valueFormatter: (v) => {
      // var amount = params.value.toLocaleString()
      //  var amountWithZero = amount + '.00'
      return FormatNumber2(v.value);
    },
    headerAlign: "right",
    headerClassName: "super-app-theme--header",
    renderHeader: (params) => <div> {"Net Amount"} </div>,
    flex: 1,
  },
];

const ItemGroup_sale = () => {
  const [pageSize, setPageSize] = useState(100);
  const [ItemReport, setItemReport] = useState([]);
  const [ItemList, setItemList] = useState([]);
  const navigate = useNavigate();
  const [total, setTotal] = useState(0);
  const [groupTotal, setGroupTotal] = useState(0);
  const [groupName, setgroupName] = useState('');
  const table = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(moment().format("YYYY-MM-DD"));
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [toDateFocus, setToDateFocus] = useState(false);

  const [fromDateMobile, setFromDateMobile] = useState(moment().format("YYYY-MM-DD"));
  const [fromDateFocusMobile, setFromDateFocusMobile] = useState(false);
  const [toDateMobile, setToDateMobile] = useState(moment().format("YYYY-MM-DD"));
  const [toDateFocusMobile, setToDateFocusMobile] = useState(false);

  

  const componentRef = useRef();
  const headers = useSelector(selectheader);
  const getItemReport = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesSubReport/groupItemWiseSale`,
        {
          table_prefix: table,
          from_date: window.innerWidth <= 768 ? fromDateMobile : window.innerWidth <= 1024 ? fromDateMobile : fromDate,
          to_date: window.innerWidth <= 768 ? toDateMobile : window.innerWidth <= 1024 ? toDateMobile : toDate,
          itm_group: groupName,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        // for (let index = 0; index < data.length; index++) {
        //   rowList.push({
        //     ...data[index],
        //     id: index + 1,
        //   });
        //   // Total.push(data[index].NET_AMT);
        // }

        var Total = [];
        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
          Total.push(data[index].NET_AMT);
        }
        var Total = Total.map((item) => Number(item))
          .reduce((a, b) => a + b, 0)
          .toLocaleString();

        setTotal(Total + ".00");
        setItemReport(rowList);
      
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
        // filter();
      });
  };

  const getAllItemGroupList = () => {
    let allList = [];
    ItemList.map((d, index) => {
      axios
        .post(
          `/api/salesSubReport/groupItemWiseSale`,
          {
            table_prefix: table,
            from_date: window.innerWidth <= 768 ? fromDateMobile : window.innerWidth <= 1024 ? fromDateMobile : fromDate,
          to_date: window.innerWidth <= 768 ? toDateMobile : window.innerWidth <= 1024 ? toDateMobile : toDate,
            itm_group: d.ITM_GROUP,
          },
          {
            headers: headers,
          }
        )
        .then((d) => {
          allList.push(d.data);
        })
        .catch((err) => console.error(err));
    });
  };

  const handlePrint = () => {
    window.print();
  };
  useEffect(() => {
    getAllItemGroupList();
  }, [ItemList]);


  const getItemlist = () => {
    dispatch(setloading(true));
    const data = {  table_prefix: table,
      from_date: window.innerWidth <= 768 ? fromDateMobile : window.innerWidth <= 1024 ? fromDateMobile : fromDate,
      to_date: window.innerWidth <= 768 ? toDateMobile : window.innerWidth <= 1024 ? toDateMobile : toDate,}
      console.log(data);
    axios
      .post(
        `/api/salesReports/groupSaleReport`,
       data,
        {
          headers: headers,
        }
      )
      .then((d) => {
      console.log(d);

        let rowList = [];
        let data = d.data;

        var groupTotal = [];
        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
          });
          groupTotal.push(data[index].NET_AMT);
        }
        var groupTotal = groupTotal
          .map((item) => Number(item))
          .reduce((a, b) => a + b, 0);
        //  const localString = Number(groupTotal).toLocaleString()
        setGroupTotal(FormatNumber2(groupTotal));
        setItemList(rowList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };
  useEffect(() => {
    getItemlist();
    getItemReport();
  }, [fromDate, toDate ,fromDateMobile, toDateMobile, groupName]);
  return (
    <div className="flex-cols l:flex  bg-slate-50 h-full  w-full  p:pt-8 pt-12 ms:pt-5 overflow-scroll ">
      <div className="w-full ll:w-1/4 d:w-1/4 l:w-1/4   p-3 print:hidden ">
       

<div className="flex print:hidden w-full ms:block t:flex l:hidden pb-2">
            <div className="w-full block t:flex">
              <div
                id="date"
                className="max-sm:block   pt-2.5 pl-0 l:hidden  mt-1.5"
                style={{ width: "100%" }}
              >
                <div
                  className="flex xl:p-3 max-sm:p-1.5 t:p-2"
                  style={window.innerWidth >= 768
                    ? {
                      width: "100%",
                      border: "1px solid #bdbdbd",
                      borderRadius: "7px",
                      }
                    : {
                    width: "100%",
                    border: "1px solid #bdbdbd",
                    borderRadius: "7px",
                  }}
                >
                  <div
                    style={{ width: "26%" }}
                    className="xl:flex xl:text-sm t:text-md max-sm:hidden t:hidden xl:items-center text-[#4a4a4a]"
                  >
                    From Date
                  </div>
                  {/* {fromDate} */}
                  <div
                    style={{
                      height: "40px",
                      width: "50%",
                      border: "1px solid #bdbdbd",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                    }}
                    className="flex w-full items-center xl:ml-1"
                  >
                    <DatePicker
                      onFocus={() => setFromDateFocusMobile(true)}
                      onClickOutside={() => setFromDateFocusMobile(false)}
                      onSelect={() => setFromDateFocusMobile(false)}
                      open={fromDateFocusMobile}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(fromDateMobile).format("YYYY-MM-DD"))}
                      onChange={(d) => {
                        setFromDateMobile(moment(d).format("YYYY-MM-DD"));
                      }}
                      readOnly
                    />
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "14%",
                      border: "1px solid #bdbdbd",
                      borderLeft: "0px",
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                      // backgroundColor:"red"
                    }}
                    className="items-center flex justify-center"
                  >
                    <DateRangeIcon
                      style={{ color: "gray" }}
                      onClick={() => {
                        setFromDateFocusMobile(true);
                      }}
                    />
                  </div>

                  <div
                    style={{ width: "15%" }}
                    className="flex justify-center items-center xl:text-md max-sm:text-xs t:text-xs text-[#4a4a4a]"
                  >
                    To
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "50%",
                      border: "1px solid #bdbdbd",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                    }}
                    className=" flex w-full items-center xl:ml-1"
                  >
                    <DatePicker
                      onFocus={() => setToDateFocusMobile(true)}
                      onClickOutside={() => setToDateFocusMobile(false)}
                      onSelect={() => setToDateFocusMobile(false)}
                      open={toDateFocusMobile}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(toDateMobile).format("YYYY-MM-DD"))}
                      onChange={(d) => {
                        setToDateMobile(moment(d).format("YYYY-MM-DD"));
                      }}
                      readOnly
                    />
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "14%",
                      border: "1px solid #bdbdbd",
                      borderLeft: "0px",
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                    }}
                    className="items-center flex justify-center"
                  >
                    <DateRangeIcon
                      style={{ color: "gray" }}
                      onClick={() => {
                        setToDateFocusMobile(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className=" pt-2 t:ml-2 flex items-center" style={{ width: "100%"}}>
                <div
                  className={
                    "bg-white drop-shadow-sm w-full px-2 py-2 rounded-md ll:ml-2 t:mt-2 ll:mt-0 "
                  }
                >
                  <div className="bg-[#e8edf6] rounded-lg  flex justify-between items-center py-1.5 px-1 ">
                    <span className="text-black font-normal ">
                      {" "}
                      {groupName === "" ? "All" : groupName}
                    </span>
                    <div className={`${groupName === "" ? "hidden" : "flex"}`}>
                      <Close
                        fontSize="20"
                        className={`text-[#b9eaf5] bg-[#3595f0]  rounded-full `}
                        onClick={() => {
                          setgroupName("");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        <Box
          sx={
            window.innerWidth <= 1280
              ? {
                  height:'300px',
                  flexDirection: "row",
                  flex: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "#E5E7EB",
                  },
                }
              : {
                height:'300px',
                  flexDirection: "row",
                  flex: 1,
                  "& .super-app-theme--header": {
                    backgroundColor: "#E5E7EB",
                  },
                }
          }
          className="md:mt-1  ll:py-3 t:py-1 md:w-full l:w-auto"
        >
          <DataGrid
            hideFooter
            rowsPerPageOptions={[100, 500, 1000, 1500]}
            sx={{ backgroundColor: "white", fontSize: 14 }}
            pageSize={100}
            rowHeight={34}
            headerHeight={40}
            showCellRightBorder
            showColumnRightBorder
            onRowClick={(data) => {
              setgroupName(data.row.ITM_GROUP);
            }}
            editMode={"row"}
            experimentalFeatures={{ newEditingApi: true }}
            rows={ItemList}
            columns={columns}
          />
        </Box>

        <div className="flex t:flex-row items-center ll:mt-3 t:mt-0 ms:mt-2  w-full d:flex-row  print:hidden ms:justify-between">
          <div>
            <Button
              onClick={handlePrint}
              sx={{
                backgroundColor: "#3595f0",
                letterSpacing: "1px",
                textTransform: "none",
                height: "38px",
              }}
              variant="contained"
              size="large"
            >
              <span> Print</span>
            </Button>
          </div>

          <div className="flex   t:w-full t:justify-end">
            <div className="font-medium flex items-center pr-1">Total : </div>
            <div className="pl-2 font-bold">{groupTotal + "/-"}</div>
          </div>
        </div>
      </div>

      {/* second  */}

      <div className="flex-1   bg-slate-50 py-3 ms:px-3 t:pr-3  w-full ">
        <div className="flex flex-col  w-full    h-full">
          <div className="flex print:hidden   w-full ms:hidden sm:hidden t:flex">
            <div className="w-full flex ">
              <div
                id="date"
                className="max-sm:block   pt-2.5 pl-0 l:block t:hidden mt-1.5"
                style={{ width: "40%" }}
              >
                <div
                  className="flex xl:p-3 max-sm:p-1.5 t:p-2"
                  style={{
                    width: "100%",
                    border: "1px solid #bdbdbd",
                    borderRadius: "7px",
                  }}
                >
                  <div
                    style={{ width: "26%" }}
                    className="xl:flex xl:text-md max-sm:hidden t:hidden xl:items-center text-[#4a4a4a]"
                  >
                    From Date
                  </div>
                  {/* {fromDate} */}
                  <div
                    style={{
                      height: "40px",
                      width: "50%",
                      border: "1px solid #bdbdbd",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                    }}
                    className="flex w-full items-center xl:ml-1"
                  >
                    <DatePicker
                      onFocus={() => setFromDateFocus(true)}
                      onClickOutside={() => setFromDateFocus(false)}
                      onSelect={() => setFromDateFocus(false)}
                      open={fromDateFocus}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(fromDate).format("YYYY-MM-DD"))}
                      onChange={(d) => {
                        setFromDate(moment(d).format("YYYY-MM-DD"));
                      }}
                      readOnly
                    />
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "14%",
                      border: "1px solid #bdbdbd",
                      borderLeft: "0px",
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                      // backgroundColor:"red"
                    }}
                    className="items-center flex justify-center"
                  >
                    <DateRangeIcon
                      style={{ color: "gray" }}
                      onClick={() => {
                        setFromDateFocus(true);
                      }}
                    />
                  </div>

                  <div
                    style={{ width: "15%" }}
                    className="flex justify-center items-center xl:text-md max-sm:text-xs t:text-xs text-[#4a4a4a]"
                  >
                    To
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "50%",
                      border: "1px solid #bdbdbd",
                      borderTopLeftRadius: "7px",
                      borderBottomLeftRadius: "7px",
                    }}
                    className=" flex w-full items-center xl:ml-1"
                  >
                    <DatePicker
                      onFocus={() => setToDateFocus(true)}
                      onClickOutside={() => setToDateFocus(false)}
                      onSelect={() => setToDateFocus(false)}
                      open={toDateFocus}
                      className=" t:text-xs xl:text-md w-full bg-transparent focus:outline-none pl-2 t:pl-1 max-sm:pl-0 text-[#4a4a4a]"
                      dateFormat=" d MMMM , yyyy "
                      selected={parseISO(moment(toDate).format("YYYY-MM-DD"))}
                      onChange={(toDate) => {
                        setToDate(moment(toDate).format("YYYY-MM-DD"));
                      }}
                      readOnly
                    />
                  </div>

                  <div
                    style={{
                      height: "40px",
                      width: "14%",
                      border: "1px solid #bdbdbd",
                      borderLeft: "0px",
                      borderTopRightRadius: "7px",
                      borderBottomRightRadius: "7px",
                    }}
                    className="items-center flex justify-center"
                  >
                    <DateRangeIcon
                      style={{ color: "gray" }}
                      onClick={() => {
                        setToDateFocus(true);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className=" pt-2 flex l:flex t:hidden items-center 0" style={{ width: "15%" }}>
                <div
                  className={
                    " drop-shadow-sm w-full px-2 py-1 rounded-md ll:ml-2 t:mt-2 ll:mt-0  "
                  }
                >
                  <div className="bg-[#e8edf6] rounded-lg  flex justify-between items-center py-0.5 px-1 ">
                    <span className="text-black font-normal ">
                      {" "}
                      {groupName === "" ? "All" : groupName}
                    </span>
                    <div className={`${groupName === "" ? "hidden" : "flex"}`}>
                      <Close
                        fontSize="18"
                        className={`text-[#b9eaf5] bg-[#3595f0]  rounded-full `}
                        onClick={() => {
                          setgroupName("");
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            ref={componentRef}
            className="ms:h-60 md:h-80 xl:h-64 print grow print:block ms:mt-4 t:mt-0  l:mt-2"
          >
            <Box
              sx={{
                backgroundColor: "white",
                "& .super-app-theme--header": {
                  backgroundColor: "#E5E7EB",
                },
                height: "100%",
              }}
            >
              
              <DataGrid
                rowsPerPageOptions={[25, 50, 75, 100]}
                sx={{ backgroundColor: "white", fontSize: 13 }}
                onPageSizeChange={(size) => setPageSize(size)}
                pageSize={pageSize}
                rowHeight={34}
                editMode={"row"}
                showCellRightBorder
                showColumnRightBorder
                experimentalFeatures={{ newEditingApi: true }}
                rows={ItemReport}
                columns={ItemGroupColoum}
              />
            </Box>
          </div>

          <div className=" t:flex ms:block w-full   py-1 pt-2 px-1">
            <div className="flex  flex-1">
              <Button
                variant="contained"
                sx={{
                  marginRight: "2px",
                  backgroundColor: "#3595f0",
                  letterSpacing: "1px",
                  textTransform: "none",
                  height: "40px",
                  width: "8%",
                }}
                className="flex items-center justify-center"
              >
                <div>Print</div>
              </Button>
              <Button
                sx={{
                  marginRight: "2px",
                  letterSpacing: "1px",
                  textTransform: "none",
                  backgroundColor: "#3595f0",
                  height: "40px",
                  width: "8%",
                }}
                variant="contained"
                className="flex items-center justify-center"
              >
                <div> Email</div>
              </Button>
              <Button
                sx={{
                  letterSpacing: "1px",
                  textTransform: "none",
                  height: "40px",
                  width: "8%",
                }}
                color="gray2"
                onClick={() => navigate("/")}
                variant="contained"
                className="flex items-center justify-center"
              >
                <div> Exit</div>
              </Button>
            </div>

            <div className="flex items-center ms:mt-2">
              <div className="font-medium flex-1 pr-1 ">Total :</div>
              <div className="pl-2 font-bold">{total + "/-"}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemGroup_sale;
