import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
var defaultValue3 = new Date();

const lastDate = localStorage.getItem("lastDate");

var defaultValue2 = moment(defaultValue3).format("YYYY-MM-DD");

export const RefreshApi = createSlice({
  name: "refresh",
  initialState: {
    payMode: {
      status: 0,
    },
    outLet: {
      status: 0,
    },
    itemGroup: {
      status: 0,
    },
    section: {
      status: 0,
    },
    restaurant: {
      status: 0,
    },
    fastFood: {
      status: 0,
    },
    ncSale: {
      status: 0,
    },
    hourly: {
      status: 0,
    },
    date: {
      dataDate: lastDate ? lastDate : defaultValue2,
    },
    alert: {
      status: false,
    },
  },
  reducers: {
    setDate(state, action) {
      state.date.dataDate = action.payload;
      localStorage.setItem("lastDate", action.payload);
    },
    setpaymodStatus(state, action) {
      state.payMode.status = state.payMode.status + 1;
    },
    setOutletStatus(state, action) {
      state.outLet.status = state.outLet.status + 1;
    },
    setItemGroupStatus(state, action) {
      state.itemGroup.status = state.itemGroup.status + 1;
    },
    setSectionStatus(state, action) {
      state.section.status = state.section.status + 1;
    },
    setResturantStatus(state, action) {
      state.restaurant.status = state.restaurant.status + 1;
    },
    setFastFoodStatus(state, action) {
      state.fastFood.status = state.fastFood.status + 1;
    },

    setNcSaleStatus(state, action) {
      state.ncSale.status = state.ncSale.status + 1;
    },
    setHourlySaleStatus(state, action) {
      state.hourly.status = state.hourly.status + 1;
    },
    setInstallStatus(state, action) {
      state.alert.status = state.payload;
    },
  },
});

export default RefreshApi.reducer;

export const {
  setSectionStatus,
  setOutletStatus,
  setFastFoodStatus,
  setHourlySaleStatus,
  setItemGroupStatus,
  setNcSaleStatus,
  setResturantStatus,
  setpaymodStatus,
  setDate,
  setInstallStatus,
} = RefreshApi.actions;

export const SelectPaymodeStatus = (state) => state.refresh.payMode.status;
export const SelectItemGroupStatus = (state) => state.refresh.itemGroup.status;
export const SelectResturantStatus = (state) => state.refresh.restaurant.status;
export const SelectFastFoodStatus = (state) => state.refresh.fastFood.status;
export const SelectNcSaleStatus = (state) => state.refresh.ncSale.status;
export const SelectHourlyStatus = (state) => state.refresh.hourly.status;
export const SelectDate = (state) => state.refresh.date.dataDate;
export const SelectalertStatus = (state) => state.refresh.alert.status;
export const SelectOutlet = (state) => state.refresh.outLet.status;
export const SelectSection = (state) => state.refresh.section.status;
