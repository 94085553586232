import React, { useState } from 'react'

import logo from '../utility/images/WGLOGO.png'

import { useDispatch } from 'react-redux'
import { useFormik } from 'formik';
import AlertModal from '../utility/component/modals/alert';
import { useNavigate } from 'react-router-dom';
import { setAlertMsg, setALertStatus } from '../utility/Redux/modal';
import {SchemaResetEmail} from './schemaValidate'
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { generateHmac } from '../utility/function';

export const ResetEmail = () => {

  const initialValues = {
    companyEmail : '',
    userEmail:'',
    }

     
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {values,errors,handleChange,handleSubmit,handleBlur} = useFormik({
      initialValues :initialValues ,
      validationSchema:SchemaResetEmail,
      onSubmit : (values) =>{

      const data = { 
             "user_email": values.userEmail,
             "company_email": values.companyEmail,
                 }
             
             
         axios.post(`/api/users/resetUserPasswordEmail`,data,{
          headers:{
            "Content-Type": "application/json",
            Authorization: `Bearer ${generateHmac(values.userEmail)}`,
          }
        } )
        .then(function (response) {
            if (response.data.status === 200) {
              // localStorage.setItem('user',JSON.stringify(values))
              dispatch(setAlertMsg('Click on link that we have sent to your email for reset password'))
              dispatch(setALertStatus(true))
              navigate('/auth/login')
              
            } else {
              alert(response.data.error)
            }
                })
        .catch(function (error) {
                   console.log(error);
                  });


        
                 }
                 })


return (



<div className=' items-center  flex w-screen bg-[#383535]  h-screen justify-center '>
       <div className=' py-7 w-auto h-auto  shadow-lg bg-white rounded-lg flex  justify-center'>

       < AlertModal/>
         <form>
          <div >
          <div className='flex  justify-center ' >
                       <div className='  flex justify-center  ' >
                          <div  >
                            <div className='w-full   flex justify-center'>
                          <div style={{marginTop:-70 ,flexDirection:"row",justifyContent:'center', height: 120,width:124, backgroundColor:'white' ,borderRadius:100}}>
                       
                               <img src={logo} alt= '' />
                            </div>
                            </div>

                          </div>
                       </div>

                       
                  </div>
                  <div className=' mt-8 mb-4 items-center w-full flex flex-col'>
                        <div >
                        Enter the email address
                        </div>
                        <div> 
                         associated with your account
                         </div>
                         </div>
                       

                       <div className=' px-0.5'>
                          <div  style={{height:1,backgroundColor:'#e0e0e0'}}>
                          </div>
                          </div>           

             <div className=' px-8' >
              
             <div className='flex flex-col items-center pt-4 text-slate-600'>
                    We will email you a link to reset
                    <div>your password</div> 
                </div>
          
           
                 <div >
             
                   <div className='pt-6 flex justify-between  items-center'>
                     
                   <div className='w-full justify-center '>
                       
                   
           <div>
    
           <TextField
                
                   size={'small'}

           
                   name='companyEmail'
                   id='companyEmail'
                   value={values.companyEmail}
                   onChange={handleChange}
                
                   error = {values.companyEmail && errors.companyEmail ? true :false}
                   label = {values.companyEmail?errors.companyEmail:'Enter Company Email'}
                   />
           
                </div>

                <div className='mt-2' >
           <TextField
                
                   size={'small'}
                   name='userEmail'
                   id='userEmail'
                   value={values.userEmail}
                   onChange={handleChange}
                   error = {values.userEmail && errors.userEmail ? true :false}
                   label = {values.userEmail?errors.userEmail:'Enter User Email'}
                  
                   />
           
                </div>

             

            </div>
                   </div> 
 <div className='w-full  pt-5 flex items-center justify-center '>
        <button type='button' onClick={ ()=>{
     
     if (!values.companyEmail || errors.companyEmail){
      dispatch(setAlertMsg('Please enter company email'))
      dispatch(setALertStatus(true))
    } else {
      if(!values.userEmail || errors.userEmail){
        dispatch(setAlertMsg('Please enter User Email'))
        dispatch(setALertStatus(true))
      } else{
        handleSubmit()
    } }


         } } className='w-full'>
          <div className=' text-white font-bold flex justify-center py-2 rounded-lg bg-[#383535]'>
           
           Submit
    
          </div>
      
        
      </button>
        </div>
       
      

        <div className='flex justify-center text-slate-600 pt-5'>
        
          New user ?

        <button onClick={()=>navigate('/auth/signup')} >
          <div className='pl-1 font-bold text-sm text-[#f5874f]'>
             SignUp
          </div>
        </button>
      </div>

      
   
      

                 </div>
           
             
                
             </div>
          </div>
          </form>
      </div>
   
       
       
    </div>
) }