import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectDate,
  SelectHourlyStatus,
  SelectPaymodeStatus,
} from "../../../utility/Redux/refresh";
import { CustomFooterTotalComponent } from "../Datagrid/hourly";
import { selectPrefix } from "../../../utility/Redux/profile";
import { setloading } from "../../../utility/Redux/modal";
import { CustomHeader } from "../Datagrid/headder";
import { FormatNumber2 } from "../../../utility/component/tools";
import { selectheader } from "../../../utility/Redux/security";
import { GeniusHeader3 } from "../../../utility/component/datagrid/header";

const HourlySaleReport = () => {
  const [hourlySaleData, sethourlySaleData] = useState([]);
  const table = useSelector(selectPrefix);
  const [total, setTotal] = useState();
  const checkDateData = useSelector(SelectDate);
  const hourlySaleStatus = useSelector(SelectHourlyStatus);
  const PaymodeStatus = useSelector(SelectPaymodeStatus);

  const dispatch = useDispatch();
  const headers = useSelector(selectheader);

  const hourlySaleColoum = [
    {
      field: "HOURS",
      width: 120,

      headerClassName: "super-app-theme--header",
      renderHeader: (params) => (
        <span

          style={{ fontSize: 15, fontWeight: 600 }}
        >
          {" "}
          Hours{" "}
        </span>
      ),
    },

    {
      field: "NET_AMT",
      flex: 1,
      headerClassName: "super-app-theme--header",
      align: "right",

      valueFormatter: (params) => {
        var amount = params.value.toLocaleString();
        var amountWithZero = amount;
        return amount;
      },
      headerAlign: "right",
      renderHeader: (params) => (
        <span
          
          style={{ fontSize: 15, fontWeight: 600 }}
        >
          {" "}
          {"Net Amount"}{" "}
        </span>
      ),
    },
  ];

  const getHourlySaledetails = () => {
    dispatch(setloading(true));
    axios
      .post(
        `/api/salesReports/hourlySales`,
        {
          table_prefix: table,
          from_date: checkDateData,
          to_date: checkDateData,
        },
        {
          headers: headers,
        }
      )
      .then((d) => {
        let rowList = [];
        let data = d.data;

        for (let index = 0; index < data.length; index++) {
          rowList.push({
            ...data[index],
            id: index + 1,
            NET_AMT: FormatNumber2(data[index].NET_AMT),
          });
        }

        sethourlySaleData(rowList);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    getHourlySaledetails();
  }, [checkDateData, PaymodeStatus]);


  return (
    <div className="ml-1">
      {hourlySaleData[0] ? (
        <div className="pb-4 ">
          <div
            style={{ fontSize: 16 }}
            className="flex  text-lg  font-bold pt-1"
          >
            Hourly Sale
          </div>
          <div className=" h-64 ml-1 mt-1  rounded-lg overflow-hidden bg-slate-100 d:w-3/12 l:w-4/12 ll:w-2/12 w-5/6 t:w-3/6 ">
            <DataGrid
              hideFooterPagination
              rowsPerPageOptions={[25, 50, 75, 100]}
              sx={{
                border: '0px solid #e8e2db',
                fontSize: 14,
                '& .super-app-theme--header': {
                  backgroundColor: '#E6E6E3',
                  display: 'none',
                },
                '& .MuiDataGrid-pagination': {
                  display: 'none',
                },
              }}
              showCellRightBorder={true}
              pageSize={25}
              components={{
                Header: () => <GeniusHeader3  columns={hourlySaleColoum} />,
                Footer: CustomFooterTotalComponent,
              }}
              componentsProps={{
                footer: { total },
              }}
              onStateChange={(state) => {
                const total = hourlySaleData
                  .map((item) => Number(item.NET_AMT))
                  .reduce((a, b) => a + b, 0);
            
                let sum = 0;
                for (let i = 0; i < hourlySaleData.length; i++) {
                  let number = parseInt(
                    hourlySaleData[i].NET_AMT.replace(",", "")
                  );
                  sum += number;
                }
                setTotal(sum);
              }}
              headerHeight={0}
              rowHeight={34}
              editMode={"row"}
              experimentalFeatures={{ newEditingApi: true }}
              rows={hourlySaleData}
              columns={hourlySaleColoum}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default HourlySaleReport;
