import  React ,{useEffect} from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { SelectAlertStatus, selectAlertMsg, setALertStatus, selectAlertOkStatus, setAlertOkStatus } from '../../Redux/modal';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../images/ico.png'
import { selectUserName } from '../../Redux/profile';
import { capitalize } from '@mui/material';

const ProfileModal = () => {
    const dispatch = useDispatch()
  const alertStatus = useSelector(SelectAlertStatus)
  const alertMsg = useSelector(selectAlertMsg)
  const username = useSelector(selectUserName)
  const okStatus = useSelector(selectAlertOkStatus)
  const handleClose = () => dispatch(setALertStatus(false))
  const myFunction = () => {
    // your logic here
    if(alertStatus){
      if(Number(okStatus) === 0){
     
        dispatch(setAlertOkStatus(Number(okStatus) + 1))
      }else {
  
        dispatch(setAlertOkStatus(0))
        dispatch(setALertStatus(false))
      }
    }

   
  };

  useEffect(() => {
    const keyDownHandler = event => {
   

      if (event.key === 'Enter') {
        event.preventDefault();

        // 👇️ your logic here
        if(alertStatus){
          myFunction();
        }
       
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [alertStatus,okStatus]);


 
  

  return (
    <div className=' rounded-lg '>

      <Modal
  open={false}
  onClose={handleClose}
  
   >
<div className='w-1/6 h-2/6 bg-brown overflow-hidden rounded-lg justify-center  ' style={ {position: 'absolute',
  top: 50,
  right:20,

  
 
  
 
  divShadow: 24,
 }}>
<div className=' bg-white h-full w-full'>
   <div className='bg-[#A9A9A9] h-1/4 text-black font-medium  flex flex-col items-center justify-center'>
    <div className='mb-1'>Admin</div>
   <div className='bg-brown rounded-full h-10 w-10 text-white text-center -mb-10 flex justify-center items-center'>
          { capitalize(username.slice(0,1)) }
         </div>
         
   </div>

   <div>
    <div className='w-full flex justify-center text-slate-900 mt-6'>
        {capitalize(username) }
        
    </div>

    <div className='w-full flex justify-center text-slate-800'>
      Arvindsharma@gmail.com
        
    </div>
       
   </div>
  
</div>
</div>
</Modal>
    </div>
  )
}

export default ProfileModal
